'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Button } from "../components/ui/Button"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/Card"
import { Badge } from "../components/ui/Badge"
import { Progress } from "../components/ui/Progress"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/Tabs"
import { 
  Trophy, Rocket, Brain, Zap, Star, Globe, ExternalLink, 
  Users, FileText, Award, Book, Code, Cpu, ChevronLeft, ChevronRight, Menu, X, Home
} from 'lucide-react'

// Add theme classes to override global styles
const themeClasses = {
  card: "!bg-purple-900/50 !border-purple-500/30 hover:!border-purple-400/50 !text-purple-100",
  cardHeader: "!border-purple-500/30",
  cardTitle: "!text-purple-100",
  cardContent: "!text-purple-200",
  badge: "!bg-purple-900/50 !text-purple-200 !border-purple-500/30",
  text: {
    primary: "!text-purple-100",
    secondary: "!text-purple-200",
    accent: "!text-purple-300"
  },
  button: "!bg-purple-700 hover:!bg-purple-600 !text-white",
  progress: "!bg-purple-950 !rounded-full !h-2.5",
  progressBar: "!bg-gradient-to-r !from-purple-400 !to-fuchsia-400"
};

const FeatureCard = ({ icon: Icon, title, value, description }) => (
  <Card className={themeClasses.card}>
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <CardTitle className={themeClasses.cardTitle}>{title}</CardTitle>
      <Icon className="h-4 w-4 text-fuchsia-400" />
    </CardHeader>
    <CardContent>
      <div className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-fuchsia-500 text-transparent bg-clip-text">{value}</div>
      <p className="text-xs text-purple-300">{description}</p>
    </CardContent>
  </Card>
)

const AchievementCard = ({ icon: Icon, title, date, description }) => (
  <Card className={themeClasses.card}>
    <CardHeader>
      <div className="flex items-center space-x-2">
        <Icon className="h-5 w-5 text-indigo-400" />
        <CardTitle className={themeClasses.cardTitle}>{title}</CardTitle>
      </div>
      <p className="text-sm text-indigo-300">{date}</p>
    </CardHeader>
    <CardContent>
      <p className="text-sm text-indigo-200">{description}</p>
    </CardContent>
  </Card>
)

const ProjectCard = ({ title, description, image, tags }) => (
  <Card className={themeClasses.card}>
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <CardHeader>
      <CardTitle className={themeClasses.cardTitle}>{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <p className="text-sm text-cyan-200 mb-4">{description}</p>
      <div className="flex flex-wrap gap-2">
        {tags.map((tag, index) => (
          <Badge key={index} className={themeClasses.badge}>{tag}</Badge>
        ))}
      </div>
    </CardContent>
  </Card>
)

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length)
  }

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length)
  }

  return (
    <div className="relative">
      <div className="overflow-hidden">
        <motion.div
          className="flex"
          animate={{ x: `${-currentIndex * 100}%` }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          {items.map((item, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <img src={item} alt={`Slide ${index + 1}`} className="w-full h-64 object-cover rounded-lg" />
            </div>
          ))}
        </motion.div>
      </div>
      <Button
        variant="outline"
        size="icon"
        className="absolute top-1/2 left-2 transform -translate-y-1/2"
        onClick={prevSlide}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        className="absolute top-1/2 right-2 transform -translate-y-1/2"
        onClick={nextSlide}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  )
}

const FloatingMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <motion.div
        initial={false}
        animate={isOpen ? "open" : "closed"}
        className="relative"
      >
        {/* Menu Items */}
        <motion.div
          variants={{
            open: { opacity: 1, y: 0 },
            closed: { opacity: 0, y: 20 }
          }}
          transition={{ duration: 0.2 }}
          className={`absolute bottom-full right-0 mb-2 ${isOpen ? 'block' : 'hidden'}`}
        >
          <div className="bg-purple-900/90 backdrop-blur-sm rounded-lg shadow-lg p-2 space-y-2 min-w-[200px] border border-purple-500/30">
            <Link to="/" className="flex items-center gap-2 text-purple-100 hover:bg-purple-800/50 p-2 rounded-lg transition-colors">
              <Home size={18} />
              Pythaverse Home
            </Link>
            <Link to="/schools" className="flex items-center gap-2 text-purple-100 hover:bg-purple-800/50 p-2 rounded-lg transition-colors">
              <Globe size={18} />
              Schools Directory
            </Link>
            <div className="border-t border-purple-700 my-2"></div>
            <div className="px-2 py-1">
              <p className="text-xs text-purple-300/60">Part of Pythaverse Network</p>
            </div>
          </div>
        </motion.div>

        {/* Toggle Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-gradient-to-r from-purple-600 to-fuchsia-600 hover:from-purple-500 hover:to-fuchsia-500 text-white p-3 rounded-full shadow-lg transition-all duration-300 hover:shadow-purple-500/25"
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </motion.div>
    </div>
  );
};

export default function SchoolPythaverseProfileV2() {
  const [activeTab, setActiveTab] = useState('overview')

  // Example school data
  const schoolData = {
    name: "Quantum Academy",
    motto: "Innovating for Tomorrow",
    description: "Quantum Academy is a leading institution in STEM education, specializing in robotics, AI, and quantum computing. Our participation in Pythaverse programs has propelled our students to new heights of innovation and achievement.",
    logo: "/quantum_logo.png?height=100&width=100",
    coverImages: [
      "/school_banner1.png?height=400&width=600",
      "/school_banner2.png?height=400&width=600",
      "/school_banner3.png?height=400&width=600"
    ],
    stats: {
      students: 500,
      pythaverseParticipants: 150,
      projectsCompleted: 75,
      awardsWon: 25
    },
    achievements: [
      { icon: Trophy, title: "Robothon 2023 Champions", date: "November 2023", description: "Our team secured first place in the annual Pythaverse Robothon, showcasing exceptional problem-solving skills and innovative thinking." },
      { icon: Star, title: "Best AI Project", date: "March 2023", description: "Recognized for developing a groundbreaking AI model that addresses real-world challenges in environmental conservation." },
      { icon: Users, title: "Community Impact Award", date: "June 2023", description: "Honored for our outreach programs that bring coding education to underserved communities, inspired by Pythaverse's mission." }
    ],
    featuredProjects: [
      { title: "EcoSense", description: "An AI-powered environmental monitoring system", image: "/project eco.webp?height=300&width=400", tags: ["AI", "IoT", "Environmental"] },
      { title: "QuantumLearn", description: "Interactive quantum computing simulation platform", image: "/project quantum.webp?height=300&width=400", tags: ["Quantum", "Education", "Simulation"] },
      { title: "RoboAssist", description: "Assistive robotics for elderly care", image: "/project robot.webp?height=300&width=400", tags: ["Robotics", "Healthcare", "AI"] }
    ],
    pythaverseJourney: [
      { year: 2020, event: "Joined Pythaverse ecosystem" },
      { year: 2021, event: "First Robothon participation" },
      { year: 2022, event: "Launched Pythaverse-inspired curriculum" },
      { year: 2023, event: "Robothon champions and multiple awards" }
    ]
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-950 via-indigo-950 to-purple-950">
      <header className="bg-gradient-to-r from-purple-900/90 via-indigo-900/90 to-purple-900/90 border-b border-purple-500/20 text-white sticky top-0 z-50 shadow-lg backdrop-blur-sm">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="relative w-12 h-12">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-500 to-fuchsia-500 rounded-full animate-pulse"></div>
              <img src={schoolData.logo} alt={schoolData.name} className="absolute inset-0.5 rounded-full object-cover bg-black/50" />
            </div>
            <div>
              <h1 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-fuchsia-500 text-transparent bg-clip-text">{schoolData.name}</h1>
              <p className="text-purple-200">{schoolData.motto}</p>
            </div>
          </div>
          <nav className="flex space-x-4">
            <Link to="#overview" className="text-sm font-medium text-purple-300 hover:text-fuchsia-300 transition-colors">Overview</Link>
            <Link to="#achievements" className="text-sm font-medium text-purple-300 hover:text-fuchsia-300 transition-colors">Achievements</Link>
            <Link to="#projects" className="text-sm font-medium text-purple-300 hover:text-fuchsia-300 transition-colors">Projects</Link>
            <Button variant="outline" size="sm" className="border-purple-400 text-purple-200 hover:bg-purple-800/30 hover:border-fuchsia-400">
              <Globe className="w-4 h-4 mr-2" />
              Visit Website
            </Button>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <section className="mb-12">
          <div className="relative rounded-lg overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-fuchsia-500/20 animate-pulse"></div>
            <Carousel items={schoolData.coverImages} />
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-fuchsia-500 text-transparent bg-clip-text">Welcome to {schoolData.name}</h2>
          <p className="text-lg mb-6 text-purple-200">{schoolData.description}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <FeatureCard icon={Users} title="Students" value={schoolData.stats.students} description="Bright minds shaping the future" />
            <FeatureCard icon={Rocket} title="Pythaverse Participants" value={schoolData.stats.pythaverseParticipants} description="Engaged in Pythaverse programs" />
            <FeatureCard icon={FileText} title="Projects Completed" value={schoolData.stats.projectsCompleted} description="Innovative solutions created" />
            <FeatureCard icon={Award} title="Awards Won" value={schoolData.stats.awardsWon} description="Recognitions in competitions" />
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-fuchsia-500 text-transparent bg-clip-text">Meet Our Robothon Team</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {[
              { name: "Sarah Chen", role: "AI Lead", image: "/team-member-1.png" },
              { name: "Alex Kumar", role: "Robotics Engineer", image: "/team-member-2.png" },
              { name: "Maya Patel", role: "Software Developer", image: "/team-member-3.png" },
              { name: "David Park", role: "Hardware Specialist", image: "/team-member-4.png" }
            ].map((member, index) => (
              <Card key={index} className={themeClasses.card}>
                <CardContent className="pt-6">
                  <div className="relative w-24 h-24 mx-auto mb-4">
                    <div className="absolute inset-0 bg-gradient-to-br from-purple-500 to-fuchsia-500 rounded-full animate-pulse"></div>
                    <img 
                      src={member.image} 
                      alt={member.name}
                      className="absolute inset-0.5 w-full h-full rounded-full object-cover"
                      onError={(e) => {
                        e.target.src = "/avatar-placeholder.svg";
                      }}
                    />
                  </div>
                  <h4 className="text-lg font-semibold text-center text-purple-100">{member.name}</h4>
                  <p className="text-sm text-center text-purple-300">{member.role}</p>
                  <div className="mt-3 flex justify-center gap-2">
                    <Badge className={themeClasses.badge}>
                      {index === 0 ? "AI" : index === 1 ? "Robotics" : index === 2 ? "Software" : "Hardware"}
                    </Badge>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>

        <Tabs defaultValue="overview" className="w-full" onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-3 mb-8 bg-purple-900/30 p-1 rounded-lg border border-purple-500/20">
            <TabsTrigger value="overview" className="data-[state=active]:bg-gradient-to-r data-[state=active]:from-purple-600 data-[state=active]:to-fuchsia-600 data-[state=active]:text-white text-purple-300">
              Pythaverse Journey
            </TabsTrigger>
            <TabsTrigger value="achievements" className="data-[state=active]:bg-gradient-to-r data-[state=active]:from-purple-600 data-[state=active]:to-fuchsia-600 data-[state=active]:text-white text-purple-300">
              Achievements
            </TabsTrigger>
            <TabsTrigger value="projects" className="data-[state=active]:bg-gradient-to-r data-[state=active]:from-purple-600 data-[state=active]:to-fuchsia-600 data-[state=active]:text-white text-purple-300">
              Featured Projects
            </TabsTrigger>
          </TabsList>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <TabsContent value="overview">
                <div className="space-y-8">
                  <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-fuchsia-500 text-transparent bg-clip-text">Our Pythaverse Journey</h3>
                  <div className="relative border-l border-purple-500/30 ml-3">
                    {schoolData.pythaverseJourney.map((item, index) => (
                      <div key={index} className="mb-10 ml-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-gradient-to-r from-purple-600 to-fuchsia-600 rounded-full -left-3 ring-8 ring-purple-950">
                          <Cpu className="w-3 h-3 text-purple-100" />
                        </span>
                        <h3 className="flex items-center mb-1 text-lg font-semibold text-purple-100">{item.year}</h3>
                        <p className="mb-4 text-base font-normal text-purple-300">{item.event}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </TabsContent>
              <TabsContent value="achievements">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {schoolData.achievements.map((achievement, index) => (
                    <AchievementCard key={index} {...achievement} />
                  ))}
                </div>
              </TabsContent>
              <TabsContent value="projects">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {schoolData.featuredProjects.map((project, index) => (
                    <ProjectCard key={index} {...project} />
                  ))}
                </div>
              </TabsContent>
            </motion.div>
          </AnimatePresence>
        </Tabs>
      </main>

      <footer className="bg-gradient-to-r from-purple-900/90 via-indigo-900/90 to-purple-900/90 border-t border-purple-500/20 text-white mt-12 py-8 backdrop-blur-sm">
        <div className="container mx-auto px-4 text-center">
          <p className="text-purple-200">&copy; 2024 {schoolData.name}. All rights reserved.</p>
          <p className="mt-2 text-sm text-purple-300">Proudly part of the Pythaverse Network</p>
          <Button variant="link" className="mt-4 text-purple-300 hover:text-fuchsia-300">
            <ExternalLink className="w-4 h-4 mr-2" />
            Learn more about Pythaverse
          </Button>
        </div>
      </footer>

      <FloatingMenu />
    </div>
  )
}