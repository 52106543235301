'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Button } from "../components/ui/Button"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/Card"
import { Badge } from "../components/ui/Badge"
import { Progress } from "../components/ui/Progress"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/Tabs"
import { 
  Trophy, Rocket, Brain, Zap, Star, Globe, ExternalLink, 
  Users, FileText, GraduationCap, Code, ChevronLeft, ChevronRight,
  Download, Menu, X, Home
} from 'lucide-react'
import styles from './school-pythaverse-profile-customizable.module.css';

const FeatureCard = ({ icon: Icon, title, value, description }) => (
  <Card className={`${styles.cardBase}`}>
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <CardTitle className={`text-sm font-medium ${styles.primaryText}`}>{title}</CardTitle>
      <Icon className="h-4 w-4 text-emerald-300" />
    </CardHeader>
    <CardContent>
      <div className={`text-2xl font-bold ${styles.gradientText}`}>{value}</div>
      <p className={`text-xs ${styles.secondaryText}`}>{description}</p>
    </CardContent>
  </Card>
)

const AchievementCard = ({ icon: Icon, title, description }) => (
  <Card className={styles.cardBase}>
    <CardHeader>
      <div className="flex items-center">
        <div className="p-2 rounded-full bg-emerald-500/20 mr-3">
          <Icon className="h-6 w-6 text-emerald-300" />
        </div>
        <CardTitle className={styles.gradientText}>{title}</CardTitle>
      </div>
    </CardHeader>
    <CardContent className={styles.cardContent}>
      <p className={`text-sm ${styles.descriptionText}`}>{description}</p>
    </CardContent>
  </Card>
)

const ProjectCard = ({ title, description, image, tags }) => (
  <Card className={`${styles.cardBase} group`}>
    <div className="relative overflow-hidden">
      <img 
        src={image} 
        alt={title} 
        className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105" 
      />
      <div className={styles.imageOverlay}></div>
    </div>
    <CardHeader>
      <CardTitle className={styles.gradientText}>{title}</CardTitle>
    </CardHeader>
    <CardContent className={styles.cardContent}>
      <p className={`text-sm mb-4 ${styles.descriptionText}`}>{description}</p>
      <div className="flex flex-wrap gap-2">
        {tags.map((tag, index) => (
          <Badge key={index} className={styles.badge}>{tag}</Badge>
        ))}
      </div>
    </CardContent>
  </Card>
)

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length)
  }

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length)
  }

  return (
    <div className="relative">
      <div className="overflow-hidden">
        <motion.div
          className="flex"
          animate={{ x: `${-currentIndex * 100}%` }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          {items.map((item, index) => (
            <div key={`slide-${index}`} className="w-full flex-shrink-0">
              <img src={item} alt={`Slide ${index + 1}`} className="w-full h-64 object-cover rounded-lg" />
            </div>
          ))}
        </motion.div>
      </div>
      <Button
        variant="outline"
        size="icon"
        className={`absolute top-1/2 left-2 transform -translate-y-1/2 ${styles.carouselButton}`}
        onClick={prevSlide}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        className={`absolute top-1/2 right-2 transform -translate-y-1/2 ${styles.carouselButton}`}
        onClick={nextSlide}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  )
}

const FloatingMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <motion.div
        initial={false}
        animate={isOpen ? "open" : "closed"}
        className="relative"
      >
        {/* Menu Items */}
        <motion.div
          variants={{
            open: { opacity: 1, y: 0 },
            closed: { opacity: 0, y: 20 }
          }}
          transition={{ duration: 0.2 }}
          className={`absolute bottom-full right-0 mb-2 ${isOpen ? 'block' : 'hidden'}`}
        >
          <div className="bg-emerald-900/90 rounded-lg shadow-lg p-2 space-y-2 min-w-[200px]">
            <Link to="/" className="flex items-center gap-2 text-emerald-100 hover:bg-emerald-800/50 p-2 rounded-lg transition-colors">
              <Home size={18} />
              Pythaverse Home
            </Link>
            <Link to="/schools" className="flex items-center gap-2 text-emerald-100 hover:bg-emerald-800/50 p-2 rounded-lg transition-colors">
              <Globe size={18} />
              Schools Directory
            </Link>
            <div className="border-t border-emerald-700 my-2"></div>
            <div className="px-2 py-1">
              <p className="text-xs text-emerald-300/60">Part of Pythaverse Network</p>
            </div>
          </div>
        </motion.div>

        {/* Toggle Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-emerald-700 hover:bg-emerald-600 text-white p-3 rounded-full shadow-lg transition-colors"
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </motion.div>
    </div>
  );
};

export default function SchoolPythaverseProfileCustomizable() {
  const [activeTab, setActiveTab] = useState('overview')

  // Updated school data with correct image paths
  const schoolData = {
    name: "Quantum Academy",
    tagline: "Empowering the next generation of tech innovators",
    description: "At Quantum Academy, we've embraced the Pythaverse ecosystem to push the boundaries of what's possible in technology education. Our journey has been marked by innovation, collaboration, and a commitment to excellence.",
    logo: "/quantum_logo.png",
    coverImages: [
      "/school_banner1.png",
      "/school_banner2.png",
      "/school_banner3.png",
    ],
    website: "https://www.quantumacademy.edu",
    location: "Tech City, Innovation State",
    foundedYear: 2010,
    featuredStats: [
      { title: "Students Impacted", value: "800+", icon: Users, description: "Bright minds shaped" },
      { title: "Pythaverse Projects", value: "50+", icon: FileText, description: "Innovative solutions created" },
      { title: "Robothon Participations", value: "5", icon: Trophy, description: "Years of competition" },
      { title: "Awards Won", value: "20+", icon: Star, description: "Recognitions received" },
    ],
    achievements: [
      { title: "Robothon 2023 Champions", icon: Trophy, description: "Our team secured first place in the annual Pythaverse Robothon, showcasing exceptional problem-solving skills and innovative thinking." },
      { title: "Most Innovative AI Project", icon: Brain, description: "Recognized for developing a groundbreaking AI model that addresses real-world challenges in environmental conservation." },
      { title: "Best STEM Education Program", icon: GraduationCap, description: "Awarded for our comprehensive STEM curriculum that integrates Pythaverse technologies and methodologies." },
    ],
    featuredProjects: [
      { title: "EcoSense", description: "An AI-powered environmental monitoring system", image: "/project eco.webp", tags: ["AI", "IoT", "Environmental"] },
      { title: "QuantumLearn", description: "Interactive quantum computing simulation platform", image: "/project quantum.webp", tags: ["Quantum", "Education", "Simulation"] },
      { title: "RoboHealth", description: "Robotic assistance for healthcare professionals", image: "/project robot.webp", tags: ["Robotics", "Healthcare", "AI"] },
    ],
    pythonProficiency: 85,
    aiProficiency: 90,
    roboticsProficiency: 80,
  }

  return (
    <div className={styles.pageContainer}>
      <header className={styles.header}>
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="relative w-12 h-12">
              <div className="absolute inset-0 bg-gradient-to-br from-emerald-500 to-emerald-700 rounded-full animate-pulse"></div>
              <img 
                src={schoolData.logo} 
                alt={`${schoolData.name} logo`} 
                className="absolute inset-0.5 rounded-full object-cover bg-emerald-950/50" 
              />
            </div>
            <div>
              <h1 className="text-2xl font-bold bg-gradient-to-r from-emerald-300 to-emerald-500 text-transparent bg-clip-text">
                {schoolData.name}
              </h1>
              <p className="text-emerald-300/80">{schoolData.tagline}</p>
            </div>
          </div>
          <nav className="flex gap-4">
            <Link to={schoolData.website} target="_blank" rel="noopener noreferrer">
              <Button variant="outline" className="border-emerald-400 text-white hover:bg-emerald-800/30 hover:border-emerald-300">
                <Globe className="w-4 h-4 mr-2" />
                Visit Our Website
              </Button>
            </Link>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <Carousel items={schoolData.coverImages} />

        {/* Blog Section */}
        <section className="mt-12 mb-16">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-emerald-300 to-emerald-500 text-transparent bg-clip-text">Latest Updates</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                title: "Our Journey to Robothon Finals",
                date: "February 15, 2024",
                image: "/project robot.webp",
                excerpt: "Follow our team's incredible journey from qualification rounds to the finals of Robothon 2024.",
                tags: ["Competition", "Achievement"]
              },
              {
                title: "New AI Lab Launch",
                date: "February 10, 2024",
                image: "/project quantum.webp",
                excerpt: "Exciting news! We've just opened our state-of-the-art AI laboratory for student research.",
                tags: ["Facility", "AI"]
              },
              {
                title: "Student Success Story",
                date: "February 5, 2024",
                image: "/project eco.webp",
                excerpt: "Meet Sarah Chen, whose AI project earned international recognition at the Global Tech Summit.",
                tags: ["Student", "Achievement"]
              }
            ].map((post, index) => (
              <Card key={index} className={`${styles.cardBase} transform hover:scale-105`}>
                <CardContent className={styles.cardContent}>
                  <h4 className={styles.gradientText}>{post.title}</h4>
                  <p className={`mt-2 ${styles.descriptionText}`}>{post.excerpt}</p>
                  <div className="flex gap-2 mt-4">
                    {post.tags.map((tag, i) => (
                      <Badge key={i} className={styles.badge}>{tag}</Badge>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>

        {/* Team Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-emerald-300 to-emerald-500 text-transparent bg-clip-text">Meet Our Robothon Team</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {[
              { name: "Sarah Chen", role: "AI Lead", image: "/team-member-1.png", skills: ["AI", "Python", "Research"] },
              { name: "Alex Kumar", role: "Robotics Engineer", image: "/team-member-2.png", skills: ["Robotics", "Hardware", "IoT"] },
              { name: "Maya Patel", role: "Software Developer", image: "/team-member-3.png", skills: ["Software", "ML", "Cloud"] },
              { name: "David Park", role: "Hardware Specialist", image: "/team-member-4.png", skills: ["Electronics", "3D Design", "PCB"] }
            ].map((member, index) => (
              <Card key={index} className="bg-gradient-to-br from-emerald-800/50 to-teal-800/50 border border-emerald-500/30 hover:border-emerald-400/50 shadow-lg hover:shadow-emerald-500/20 transition-all duration-300 transform hover:scale-105">
                <CardContent className="pt-6">
                  <div className="relative w-32 h-32 mx-auto mb-4">
                    <div className="absolute inset-0 bg-gradient-to-br from-emerald-500 to-emerald-700 rounded-full animate-pulse"></div>
                    <img 
                      src={member.image} 
                      alt={member.name}
                      className="absolute inset-0.5 w-full h-full rounded-full object-cover"
                      onError={(e) => {
                        e.target.src = "/avatar-placeholder.svg";
                      }}
                    />
                  </div>
                  <h4 className="text-lg font-semibold text-center text-emerald-100">{member.name}</h4>
                  <p className="text-sm text-center text-emerald-300 mb-3">{member.role}</p>
                  <div className="flex flex-wrap justify-center gap-2">
                    {member.skills.map((skill, i) => (
                      <Badge key={i} className="bg-emerald-800/50 text-emerald-200 border border-emerald-500/30">
                        {skill}
                      </Badge>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>

        <div className="mt-8 grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {schoolData.featuredStats.map((stat, index) => (
            <FeatureCard key={index} {...stat} />
          ))}
        </div>

        <Tabs defaultValue={activeTab} className="mt-8" onValueChange={setActiveTab}>
          <TabsList className={styles.tabsList}>
            <TabsTrigger 
              value="overview" 
              className={styles.tabTrigger}
            >
              Overview
            </TabsTrigger>
            <TabsTrigger 
              value="achievements" 
              className={styles.tabTrigger}
            >
              Achievements
            </TabsTrigger>
            <TabsTrigger 
              value="projects" 
              className={styles.tabTrigger}
            >
              Projects
            </TabsTrigger>
            <TabsTrigger 
              value="skills" 
              className={styles.tabTrigger}
            >
              Skills
            </TabsTrigger>
          </TabsList>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              <TabsContent value="overview">
                <Card className="bg-gradient-to-br from-emerald-800/50 to-teal-800/50 border border-emerald-500/30">
                  <CardHeader>
                    <CardTitle className="text-emerald-100">About {schoolData.name}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-emerald-200">{schoolData.description}</p>
                    <div className="mt-4 flex flex-col gap-2 text-emerald-200">
                      <p><strong className="text-emerald-100">Location:</strong> {schoolData.location}</p>
                      <p><strong className="text-emerald-100">Founded:</strong> {schoolData.foundedYear}</p>
                      <p><strong className="text-emerald-100">Pythaverse Journey:</strong> Joined in 2020</p>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
              <TabsContent value="achievements">
                <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {schoolData.achievements.map((achievement, index) => (
                    <AchievementCard key={index} {...achievement} />
                  ))}
                </div>
              </TabsContent>
              <TabsContent value="projects">
                <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {schoolData.featuredProjects.map((project, index) => (
                    <ProjectCard key={index} {...project} />
                  ))}
                </div>
              </TabsContent>
              <TabsContent value="skills">
                <Card className="bg-gradient-to-br from-emerald-800/50 via-teal-800/40 to-emerald-900/60 border border-emerald-500/30">
                  <CardHeader>
                    <CardTitle className="text-lg bg-gradient-to-r from-emerald-200 to-emerald-400 text-transparent bg-clip-text">
                      Pythaverse Proficiency
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <div>
                      <div className="flex justify-between mb-2">
                        <span className={styles.primaryText}>Python</span>
                        <span className={styles.secondaryText}>{schoolData.pythonProficiency}%</span>
                      </div>
                      <Progress 
                        value={schoolData.pythonProficiency} 
                        className={styles.progressBar}
                        barClassName={styles.progressBarFill}
                      />
                    </div>
                    <div>
                      <div className="flex justify-between mb-2">
                        <span className={styles.primaryText}>Artificial Intelligence</span>
                        <span className={styles.secondaryText}>{schoolData.aiProficiency}%</span>
                      </div>
                      <Progress 
                        value={schoolData.aiProficiency} 
                        className={styles.progressBar}
                        barClassName={styles.progressBarFill}
                      />
                    </div>
                    <div>
                      <div className="flex justify-between mb-2">
                        <span className={styles.primaryText}>Robotics</span>
                        <span className={styles.secondaryText}>{schoolData.roboticsProficiency}%</span>
                      </div>
                      <Progress 
                        value={schoolData.roboticsProficiency} 
                        className={styles.progressBar}
                        barClassName={styles.progressBarFill}
                      />
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
            </motion.div>
          </AnimatePresence>
        </Tabs>
      </main>

      <footer className={styles.footer}>
        <div className="container mx-auto px-4 text-center">
          <div className="mb-6">
            <div className="relative w-20 h-20 mx-auto mb-4">
              <div className="absolute inset-0 bg-gradient-to-br from-emerald-500 to-emerald-700 rounded-full animate-pulse"></div>
              <img 
                src={schoolData.logo} 
                alt={`${schoolData.name} logo`} 
                className="absolute inset-1 rounded-full object-cover bg-emerald-950/50" 
              />
            </div>
            <h3 className="text-xl font-bold text-emerald-100 mb-2">{schoolData.name}</h3>
            <p className="text-emerald-300">{schoolData.location}</p>
          </div>
          <p className="text-emerald-200">&copy; 2024 {schoolData.name}. All rights reserved.</p>
          <p className="mt-2 text-sm text-emerald-400">Proudly part of the Pythaverse Network</p>
          <div className="mt-4 flex justify-center gap-4">
            <Button variant="outline" size="sm" className="border-emerald-500/50 text-emerald-300 hover:bg-emerald-800/30">
              <Globe className="w-4 h-4 mr-2" />
              School Website
            </Button>
            <Button variant="outline" size="sm" className="border-emerald-500/50 text-emerald-300 hover:bg-emerald-800/30">
              <ExternalLink className="w-4 h-4 mr-2" />
              Contact Us
            </Button>
          </div>
        </div>
      </footer>

      <FloatingMenu />
    </div>
  )
}