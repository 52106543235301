import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, Users, Rocket, Award, Globe, ArrowRight } from 'lucide-react';
import styles from './PartnerSummit.module.css';

function PartnerSummit() {
  return (
    <div className={styles.container}>
      <div className="container mx-auto px-4">
        {/* Hero Section with Register Button */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={styles.heroSection}
        >
          <h1 className={`text-4xl md:text-5xl font-bold mb-4 ${styles.title}`}>
            Pythaverse Business Partners Summit 2024
          </h1>
          <p className={`text-xl md:text-2xl mb-4 ${styles.subtitle}`}>
            Effective Use of AI and Robotics in STEM Education
          </p>
          
          {/* Moved Register Button here */}
          <a 
            href="https://forms.gle/FALZtkD5EKPvk6Ad7" 
            target="_blank"
            rel="noopener noreferrer"
            className={styles.primaryButton}
          >
            <span className="flex items-center">Summit Registration <ArrowRight className="ml-2" size={16} /></span>
          </a>

          <div className={`${styles.eventInfo} mt-6`}>
            <Calendar className={styles.icon} size={20} />
            <span>25 November 2024, 8:30 AM – 4:30 PM</span>
            <Globe className={styles.icon} size={20} />
            <span>Bangkok, Thailand</span>
          </div>
        </motion.div>

        {/* Key Benefits Section */}
        <div className={`${styles.benefitsSection} grid grid-cols-1 md:grid-cols-3 gap-6 mb-12`}>
          <div className={styles.benefitCard}>
            <Rocket className={styles.benefitIcon} />
            <h3>Innovation Access</h3>
            <p>Be the first to experience our latest AI and robotics solutions</p>
          </div>
          <div className={styles.benefitCard}>
            <Users className={styles.benefitIcon} />
            <h3>Network Growth</h3>
            <p>Connect with education leaders and tech innovators</p>
          </div>
          <div className={styles.benefitCard}>
            <Award className={styles.benefitIcon} />
            <h3>Market Expansion</h3>
            <p>Unlock new opportunities in the STEM education market</p>
          </div>
        </div>

        {/* Agenda Sections */}
        <div className={`${styles.agendaContainer} mb-12`}>
          <div className={styles.agendaCard}>
            <div className={styles.agendaHeader}>
              <h2 className={`${styles.agendaTitle} text-black`}>Summit Agenda</h2>
              <div className={`${styles.agendaSubtitle} text-black`}>
                <Calendar className={styles.icon} size={20} />
                <span className="text-black">25 November 2024</span>
                <Globe className={styles.icon} size={20} />
                <span className="text-black">Bangkok, Thailand</span>
              </div>
            </div>

            <div className={styles.agendaSection}>
              <h3 className={styles.sessionTitle}>Morning Session</h3>
              <div className={styles.timelineContainer}>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>9:00 AM</span>
                  <div className={styles.timelineContent}>
                    <h4>Opening Remarks and Keynote</h4>
                    <p>Maximizing AI and Robotics for STEM Education with Pythaverse</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>9:15 AM</span>
                  <div className={styles.timelineContent}>
                    <h4>Product Announcements</h4>
                    <p>Be the first to learn about our groundbreaking new products and services, including AI tools, smart Leanbot enhancements and advanced IoT solutions.</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>10:15 AM</span>
                  <div className={styles.timelineContent}>
                    <h4>Break</h4>
                    <p>15 minutes</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>10:30 AM</span>
                  <div className={styles.timelineContent}>
                    <h4>Product Upgrades and Releases</h4>
                    <p>Stay ahead with personalized digital learning environments and collaborative workspaces designed for modern education.</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>11:30 AM</span>
                  <div className={styles.timelineContent}>
                    <h4>Case Studies in Success</h4>
                    <p>Discover real-world success stories of Pythaverse implementations in educational programs.</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>12:10 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Recognition and Awards</h4>
                    <p>Celebrate outstanding achievements in applying Pythaverse technologies.</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>12:30 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Lunch Break</h4>
                    <p>1 hour</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.agendaDivider}></div>

            <div className={styles.agendaSection}>
              <h3 className={styles.sessionTitle}>Afternoon Session</h3>
              <div className={styles.timelineContainer}>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>2:00 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Pythaverse Enablement</h4>
                    <p>Workspace and Support and AI tools for Partners</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>2:30 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Business Opportunities</h4>
                    <p>Review successful case studies and explore new ways to expand your business and increase revenue.</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>3:15 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Break</h4>
                    <p>15 minutes</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>3:30 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Market Strategy</h4>
                    <p>Insights on the expansion plans in Southeast Asia and beyond.</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>4:10 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Ecosystem for Business Partners</h4>
                    <p>Engage with key content providers and hardware bundling partners to support your growth.</p>
                  </div>
                </div>
                <div className={styles.timelineItem}>
                  <span className={styles.timelineTime}>4:30 PM</span>
                  <div className={styles.timelineContent}>
                    <h4>Conference Ends</h4>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Partnership CTA Section - removed Register button from here */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className={styles.partnershipCta}
        >
          <h2 className={styles.ctaTitle}>Become a Pythaverse Partner</h2>
          <p className={styles.ctaText}>
            Join our network of innovative education partners and help shape the future of STEM learning. 
            As a Pythaverse partner, you'll get exclusive access to our cutting-edge AI tools, comprehensive support, 
            and a growing market of schools embracing digital transformation.
          </p>
          <div className={styles.primaryButtons}>
            <a 
              href="https://forms.gle/UhYeqSZb6vykHKth8"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.primaryButton}
            >
              <span className="flex items-center">
                Become a Partner <ArrowRight className="ml-2" size={16} />
              </span>
            </a>
          </div>
        </motion.div>

        {/* Contact Section */}
        <div className={styles.contactSection}>
          
        </div>
      </div>
    </div>
  );
}

export default PartnerSummit;