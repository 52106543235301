import React, { useState } from 'react';

export function DropdownMenu({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative inline-block text-left">
      {React.Children.map(children, child => {
        return React.cloneElement(child, { isOpen, setIsOpen });
      })}
    </div>
  );
}

export function DropdownMenuTrigger({ children, isOpen, setIsOpen }) {
  return (
    <div onClick={() => setIsOpen(!isOpen)}>
      {children}
    </div>
  );
}

export function DropdownMenuContent({ children, isOpen }) {
  if (!isOpen) return null;
  return (
    <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        {children}
      </div>
    </div>
  );
}

export function DropdownMenuItem({ children, onClick }) {
  return (
    <div
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
      role="menuitem"
      onClick={onClick}
    >
      {children}
    </div>
  );
} 