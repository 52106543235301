import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './Home.module.css';

function Home() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    function calculateTimeLeft() {
      const eventDate = new Date('2024-11-24T08:00:00').getTime();
      const now = new Date().getTime();
      const difference = eventDate - now;

      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000)
        };
      }
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const titleVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      }
    }
  };

  const letterVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 200
      }
    }
  };

  const titleWords = ["WELCOME PYTHAVERSE LEANBOT TEAMS TO", "ROBOTHON 2024"];

  return (
    <>
      <section id="hero" 
        className={styles.heroSection}
        style={{ 
          backgroundImage: 'url("/hero-bg.jpg")',
        }}
      >
        

        <div className="container mx-auto px-4 relative z-10">
          <motion.h1 
            className="text-3xl md:text-5xl font-bold mb-6 tracking-wider flex flex-col items-center gap-y-4"
            initial="hidden"
            animate="visible"
            variants={titleVariants}
          >
            <motion.div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
              {titleWords[0].split(" ").map((word, index) => (
                <motion.span
                  key={index}
                  variants={letterVariants}
                  className={`inline-block whitespace-nowrap ${styles.title}`}
                >
                  {word}
                </motion.span>
              ))}
            </motion.div>
            <motion.div 
              variants={letterVariants}
              className={`whitespace-nowrap ${styles.title}`}
            >
              {titleWords[1]}
            </motion.div>
          </motion.h1>

          <motion.p 
            className={`text-2xl md:text-4xl mb-12 ${styles.subtitle} text-center`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ 
              opacity: [0, 1, 1, 1, 1],
              scale: [0.5, 1.2, 0.9, 1.1, 1],
              y: [50, -20, 10, -5, 0]
            }}
            transition={{ 
              delay: 3.5,
              duration: 1.5,
              times: [0, 0.2, 0.5, 0.8, 1],
              ease: "easeInOut"
            }}
          >
            Demonstrate Your Talent and Innovation in Robotics and AI
          </motion.p>

          {/* Countdown Timer */}
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 max-w-2xl mx-auto mb-12">
            {Object.entries(timeLeft).map(([key, value]) => (
              <div key={key} className={styles.countdownCard}>
                <div className={styles.countdownNumber}>{value}</div>
                <div className={styles.countdownLabel}>{key}</div>
              </div>
            ))}
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col md:flex-row gap-4 justify-center">
            <Link to="/media" className={styles.whiteButton}>
              Media Information kit
            </Link>
            <Link to="/students" className={styles.primaryButton}>
              Students: get your AI avatar!
            </Link>
            <Link to="/schools" className={styles.secondaryButton}>
              Schools: get your showcase!
            </Link>
          </div>
        </div>
      </section>

      <motion.section 
        id="about" 
        className={styles.aboutSection}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <h2 className={styles.sectionTitle}>About Robothon</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { title: "Innovation", text: "Push the boundaries of AI and robotics in this groundbreaking competition." },
              { title: "Collaboration", text: "Connect with brilliant minds from across Southeast Asia." },
              { title: "Learning", text: "Gain hands-on experience with cutting-edge technologies." }
            ].map((feature, index) => (
              <div key={index} className={styles.featureCard}>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureText}>{feature.text}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="container mx-auto px-4 mt-20">
          <h2 className={styles.sectionTitle}>For Parents</h2>
          <p className={styles.sectionSubtitle}>
            Discover how to support your child's journey in technology and innovation
          </p>
          <div className="text-center">
            <Link 
              to="/parents"
              className={styles.primaryButton}
            >
              Parent Resources
            </Link>
          </div>
        </div>
      </motion.section>

      <motion.section 
        id="register" 
        className={styles.registerSection}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4 text-center">
          <h2 className={styles.sectionTitle}>Join Robothon 2024</h2>
          <p className={styles.sectionSubtitle}>Be part of the largest STEM Robotics event in Southeast Asia</p>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <Link to="/media" className={styles.whiteButton}>
              Media Information kit
            </Link>
            <Link to="/students" className={styles.secondaryButton}>
              Student Registration
            </Link>
            <Link to="/schools" className={styles.primaryButton}>
              School Registration
            </Link>
            <Link to="/partner-summit" className={styles.secondaryButton}>
              Partner Registration
            </Link>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default Home;