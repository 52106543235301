import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/Button";
import { Card } from "../components/ui/Card";
import { Progress } from "../components/ui/Progress";
import { X } from 'lucide-react';
import styles from './Parents.module.css';

function Parents() {
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const quizQuestions = [
    {
      question: "How comfortable are you with helping your child with technology-related learning?",
      options: [
        "Very comfortable - I work in tech",
        "Somewhat comfortable - I use technology daily",
        "Neutral - I can manage basics",
        "Not very comfortable - I struggle with new tech",
      ]
    },
    {
      question: "What is your biggest concern about your child's technology education?",
      options: [
        "Screen time balance",
        "Quality of online content",
        "Keeping up with rapid changes",
        "Cost of resources and tools"
      ]
    },
    {
      question: "How does your child primarily learn about technology?",
      options: [
        "School programs",
        "Online courses",
        "Self-learning",
        "Not currently learning tech"
      ]
    },
    {
      question: "What are your goals for your child's tech education?",
      options: [
        "Future career preparation",
        "Problem-solving skills",
        "Creative expression",
        "Basic digital literacy"
      ]
    }
  ];

  const handleAnswer = (questionIndex, answerIndex) => {
    setAnswers({
      ...answers,
      [questionIndex]: answerIndex
    });
    
    if (questionIndex === quizQuestions.length - 1) {
      setShowResults(true);
    } else {
      setCurrentQuestion(questionIndex + 1);
    }
  };

  const getRecommendations = () => {
    // Simple recommendation logic based on answers
    const recommendations = [];
    
    if (answers[0] <= 1) {
      recommendations.push({
        title: "Tech-Savvy Parent Track",
        description: "Leverage your tech knowledge to guide your child through advanced projects in Pythaverse.",
        action: "Join advanced parent-mentor program",
        formLink: "https://forms.gle/PxWhajzfWewwSkVm7" // Replace with actual form link
      });
    } else {
      recommendations.push({
        title: "Parent Learning Journey",
        description: "Start with our basic tech concepts course designed for parents.",
        action: "Access free parent tech guides",
        formLink: "https://forms.gle/HrGy4RAZctL7SSkt8" // Replace with actual form link
      });
    }

    recommendations.push({
      title: "Pythaverse Robothon 2024",
      description: "Give your child the opportunity to participate in our flagship robotics competition.",
      action: "Learn about Robothon",
      formLink: "https://www.robothon.asia"
    });

    return recommendations;
  };

  return (
    <div className={styles.container}>
      <div className="container mx-auto px-4">
        <div className={styles.contentWrapper}>
          <section className="mb-20">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center mb-12"
            >
              <h1 className={`text-3xl md:text-4xl lg:text-5xl font-bold mb-4 ${styles.mainTitle}`}>
                Robothon 2024: A Parent's Guide
              </h1>
              <p className={`text-base md:text-lg lg:text-xl mb-8 ${styles.mainSubtitle}`}>
                Learn how your child can benefit from participating in Southeast Asia's largest STEM Robotics competition. Join our waitlist to receive our comprehensive parent guide and exclusive tools
                to support your child's tech education journey
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                <a 
                  href="https://forms.gle/gnRLXRooQMmtwsV16"
                  target="_blank"
                  rel="noopener noreferrer" 
                  className={`w-full sm:w-auto ${styles.primaryButton}`}
                >
                  Register Robothon 2025
                </a>
                <a 
                  href="https://forms.gle/kUk3UhQ9JxdM9WmHA"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`w-full sm:w-auto ${styles.secondaryButton}`}
                  onClick={() => setIsModalOpen(false)}
                >
                  Join AI tool Waitlist
                </a>
              </div>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
              <Card className={styles.timelineCard}>
                <h3 className={styles.timelineTitle}>Robothon 2024 Timeline: 24 Nov 2024</h3>
                <h4 className={styles.timelineSubtitle}>Subject to change</h4>
                <div className={styles.timeline}>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>8:00am to 9:00am</span>
                    <p className={styles.timelineText}>Registration</p>
                  </div>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>9:00am to 12:00pm</span>
                    <p className={styles.timelineText}>Competition</p>
                  </div>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>12:00pm to 2:00pm</span>
                    <p className={styles.timelineText}>Lunch</p>
                  </div>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>3:00pm to 5:00pm</span>
                    <p className={styles.timelineText}>Awards Ceremony</p>
                  </div>
                </div>
              </Card>

              <Card className={styles.timelineCard}>
                <h3 className={styles.timelineTitle}>Travel Information</h3>
                <div className={styles.timeline}>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>Location</span>
                    <p className={styles.timelineText}>International Robothon 2024 – SEA COMPLEX CONVENTION & COMMUNITY HALL. Address: UGJR8+WVP, Thai Ban, Mueang Samut Prakan District, Samut Prakan 10280, Thailand</p>
                  </div>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>Google maps</span>
                    <p className={styles.timelineText}>
                      <a 
                        href="https://maps.app.goo.gl/jZfrqRGzTwRPRJCVA"
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        Click to view on Google Maps
                      </a>
                    </p>
                  </div>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>Guidelines</span>
                    <p className={styles.timelineText}>
                      <a 
                        href="https://dynabookedu-my.sharepoint.com/:b:/g/personal/talha_khan_eduspec_com_my/Eb0JxsXgTzBEoJ2Tuere_7MBVhdd4gL-svTCgI_qOH_pfw?e=0WgHnR"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        International Robothon 2024 – Guest Guide
                      </a>
                    </p>
                  </div>
                  <div className={styles.timelineItem}>
                    <span className={styles.timelineDate}>Hot line support</span>
                    <p className={styles.timelineText}>TBA</p>
                  </div>
                </div>
              </Card>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
              <Card className={styles.infoCard}>
                <h3 className={styles.infoTitle}>What is Robothon?</h3>
                <p className={styles.infoText}>
                  Robothon is an innovative competition that combines robotics, AI, and problem-solving skills. 
                  Students work on real-world challenges while learning cutting-edge technology.
                </p>
              </Card>
              <Card className={styles.infoCard}>
                <h3 className={styles.infoTitle}>Benefits for Your Child</h3>
                <ul className={styles.benefitsList}>
                  <li>Hands-on experience with robotics and AI</li>
                  <li>Development of critical thinking skills</li>
                  <li>Collaboration with peers</li>
                  <li>Exposure to STEM careers</li>
                </ul>
              </Card>
              <Card className={styles.infoCard}>
                <h3 className={styles.infoTitle}>How to Participate</h3>
                <p className={styles.infoText}>
                  Students can participate through their schools or as individual teams. 
                  Registration opens soon - join our waitlist to get early access.
                </p>
              </Card>
            </div>

            <div className={`${styles.ctaSection} px-4 sm:px-6 md:px-8`}>
              <h3 className={`text-2xl md:text-3xl ${styles.ctaTitle}`}>Ready to Support Your Child's STEM Journey?</h3>
              <p className={`text-base md:text-lg ${styles.ctaText}`}>
                Take our quick assessment to receive personalized recommendations and resources.
              </p>
              <Button 
                onClick={() => setIsModalOpen(true)}
                className={`${styles.ctaButton} w-full sm:w-auto`}
              >
                Start Parent Assessment
              </Button>
            </div>
          </section>

          <AnimatePresence>
            {isModalOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.modalOverlay}
                onClick={(e) => {
                  if (e.target === e.currentTarget) setIsModalOpen(false);
                }}
              >
                <motion.div
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.95, opacity: 0 }}
                  className={`${styles.modalContent} mx-4`}
                >
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className={styles.modalClose}
                  >
                    <X size={20} />
                  </button>

                  {!showResults && (
                    <div className="p-6">
                      <Progress 
                        value={(currentQuestion / quizQuestions.length) * 100} 
                        className={styles.progressBar}
                        indicatorClassName={styles.progressIndicator}
                      />
                      
                      <h2 className={`text-2xl font-bold mb-6 text-gray-900 text-center`}>
                        {quizQuestions[currentQuestion].question}
                      </h2>
                      <div className="space-y-4">
                        {quizQuestions[currentQuestion].options.map((option, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              handleAnswer(currentQuestion, index);
                              if (currentQuestion === quizQuestions.length - 1) {
                                setShowResults(true);
                              }
                            }}
                            className={`w-full text-left p-4 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 transition-colors`}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  {showResults && (
                    <div className="resultsContent p-4 md:p-6">
                      <h2 className={`${styles.resultTitle} text-2xl md:text-3xl`}>
                        Your Personalized Recommendations
                      </h2>

                      <div className={`${styles.resultGrid} gap-4 md:gap-6`}>
                        {getRecommendations().map((rec, index) => (
                          <Card key={index} className={styles.resultCard}>
                            <div className={styles.resultCardContent}>
                              <h3 className={styles.resultCardTitle}>{rec.title}</h3>
                              <p className={styles.resultCardText}>{rec.description}</p>
                              <a 
                                href={rec.formLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.secondaryButton}
                              >
                                {rec.action}
                              </a>
                            </div>
                          </Card>
                        ))}
                      </div>

                      <div className={`${styles.modalCta} mt-6 md:mt-8`}>
                        <h3 className={styles.ctaTitle}>Ready to Start?</h3>
                        <p className={styles.ctaText}>
                          Join our waitlist to receive our comprehensive parent guide and exclusive tools
                          to support your child's tech education journey.
                        </p>
                        <a 
                          href="https://forms.gle/ijDw3KvEe6YEB8W99"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.primaryButton}
                          onClick={() => setIsModalOpen(false)}
                        >
                          Join Waitlist
                        </a>
                      </div>
                    </div>
                  )}
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default Parents; 