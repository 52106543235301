import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/Card';
import { Badge } from '../components/ui/Badge';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import { Cpu, Code, Rocket, Trophy, Zap, Star } from 'lucide-react';

// Custom theme classes to override global styles
const themeClasses = {
  card: "!bg-blue-900/50 !border-blue-700/50 hover:!border-blue-500/50 !text-blue-100",
  cardHeader: "!border-blue-700/50",
  cardTitle: "!text-blue-100",
  cardContent: "!text-blue-200",
  badge: "!bg-blue-900/50 !text-blue-200 !border-blue-500/30",
  text: {
    primary: "!text-blue-100",
    secondary: "!text-blue-200",
    accent: "!text-blue-300"
  },
  button: "!bg-blue-700 hover:!bg-blue-600 !text-white",
  progress: "!bg-blue-950 !rounded-full !h-2.5",
  progressBar: "!bg-gradient-to-r !from-blue-400 !to-indigo-400"
};

export default function StudentShowcase() {
  const [activeProject, setActiveProject] = useState(0);

  const projects = [
    { name: "AI-Powered Chess Bot", icon: <Cpu className="w-6 h-6 text-blue-400" />, description: "Developed an AI chess engine capable of beating grandmasters." },
    { name: "Robotic Arm Controller", icon: <Cpu className="w-6 h-6 text-blue-400" />, description: "Created a neural interface to control a robotic arm with thoughts." },
    { name: "Quantum Algorithm Simulator", icon: <Code className="w-6 h-6 text-blue-400" />, description: "Built a simulator for testing quantum computing algorithms." },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-950 via-indigo-950 to-blue-900">
      

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8 mb-12 items-center">
          <div className="relative w-64 h-64">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-indigo-500 rounded-full animate-pulse"></div>
            <img src="/team-member-2.png" alt="Student Avatar" className="absolute inset-2 rounded-full object-cover" />
          </div>
          <div className="flex-grow text-center md:text-left">
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-indigo-400 text-transparent bg-clip-text">
              Lee AI Engineer
            </h1>
            <p className="mb-4 text-xl text-blue-200">Future AI and Robotics Engineer</p>
            <div className="flex flex-wrap justify-center md:justify-start gap-2">
              <Badge className={themeClasses.badge}>AI Enthusiast</Badge>
              <Badge className={themeClasses.badge}>Robotics Expert</Badge>
              <Badge className={themeClasses.badge}>Quantum Computing</Badge>
            </div>
          </div>
        </div>

        <Tabs defaultValue="projects" className="w-full">
          <TabsList className="grid w-full grid-cols-3 mb-8 bg-blue-900/30 p-1 rounded-lg border border-blue-700/50">
            <TabsTrigger value="projects" className="data-[state=active]:bg-blue-700 data-[state=active]:text-white text-blue-200">
              Projects
            </TabsTrigger>
            <TabsTrigger value="achievements" className="data-[state=active]:bg-blue-700 data-[state=active]:text-white text-blue-200">
              Achievements
            </TabsTrigger>
            <TabsTrigger value="skills" className="data-[state=active]:bg-blue-700 data-[state=active]:text-white text-blue-200">
              Skills
            </TabsTrigger>
          </TabsList>
          
          <TabsContent value="projects">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              {projects.map((project, index) => (
                <Card 
                  key={index} 
                  className={`${themeClasses.card} ${
                    activeProject === index ? 'ring-2 ring-blue-400' : ''
                  }`} 
                  onClick={() => setActiveProject(index)}
                >
                  <CardHeader>
                    <CardTitle className="flex items-center text-blue-100">
                      {project.icon}
                      <span className="ml-2">{project.name}</span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-blue-200">{project.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>

            <Card className={themeClasses.card}>
              <CardHeader>
                <CardTitle className="flex items-center text-blue-100">
                  {projects[activeProject].icon}
                  <span className="ml-2">{projects[activeProject].name}</span>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="aspect-video bg-blue-950/50 rounded-lg mb-4 flex items-center justify-center">
                  <Rocket className="w-16 h-16 text-blue-400 animate-pulse" />
                  <span className="ml-4 text-lg text-blue-200">Project Demo Placeholder</span>
                </div>
                <p className="mb-4 text-blue-200">
                  Detailed description of the {projects[activeProject].name} project, including the challenges faced, technologies used, and outcomes achieved.
                </p>
                <Button className="bg-blue-700 hover:bg-blue-600 text-white">View Full Project</Button>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="achievements">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                { title: "First Place", event: "National AI Challenge 2023", icon: <Trophy className="w-6 h-6 text-yellow-400" /> },
                { title: "Best Innovation", event: "Robothon 2023", icon: <Zap className="w-6 h-6 text-blue-400" /> },
                { title: "Rising Star Award", event: "Young Inventors Conference", icon: <Star className="w-6 h-6 text-purple-400" /> },
                { title: "Perfect Score", event: "International Robotics Olympiad", icon: <Cpu className="w-6 h-6 text-green-400" /> },
              ].map((achievement, index) => (
                <Card key={index} className={themeClasses.card}>
                  <CardHeader>
                    <CardTitle className="flex items-center text-blue-100">
                      {achievement.icon}
                      <span className="ml-2">{achievement.title}</span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-blue-200">{achievement.event}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </TabsContent>

          <TabsContent value="skills">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                { name: "Machine Learning", level: 90 },
                { name: "Robotics Programming", level: 85 },
                { name: "Quantum Computing", level: 70 },
                { name: "Data Structures & Algorithms", level: 95 },
                { name: "Computer Vision", level: 80 },
                { name: "Natural Language Processing", level: 75 },
              ].map((skill, index) => (
                <Card key={index} className={themeClasses.card}>
                  <CardHeader>
                    <CardTitle className="text-blue-100">{skill.name}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="w-full bg-blue-950 rounded-full h-2.5">
                      <div 
                        className="bg-gradient-to-r from-blue-400 to-indigo-400 h-2.5 rounded-full transition-all duration-1000 ease-out" 
                        style={{ width: `${skill.level}%` }}
                      />
                    </div>
                    <p className="text-right mt-1 text-blue-200">{skill.level}%</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </main>

      <footer className="bg-blue-900/90 border-t border-blue-700/50 py-8 mt-12">
        <div className="container mx-auto px-4 text-center">
          <p className="text-blue-200">&copy; 2024 Pythaverse Robothon. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}