'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Button } from "../components/ui/Button"
import { Input } from "../components/ui/Input"
import { Badge } from "../components/ui/Badge"
import { Card } from "../components/ui/Card"
import { Progress } from "../components/ui/Progress"
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from "../components/ui/DropdownMenu"
import { Search, Filter, ExternalLink, Globe, Star, Trophy } from 'lucide-react'

const SpaceBackground = () => (
  <div className="fixed inset-0 z-0">
    <div className="absolute inset-0 bg-gradient-to-b from-indigo-900 via-purple-900 to-pink-800"></div>
    {[...Array(50)].map((_, i) => (
      <div
        key={i}
        className="star"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 5}s`,
        }}
      ></div>
    ))}
  </div>
)

const AvatarCard = ({ avatar }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      whileHover={{ scale: 1.05 }}
      className="relative"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <Card className="overflow-hidden bg-gradient-to-br from-purple-600 to-pink-500 hover:shadow-xl transition-shadow duration-300 border-2 border-yellow-300">
        <div className="relative aspect-square">
          <img 
            src={avatar.imageUrl} 
            alt={`Avatar of ${avatar.creator}`}
            className="w-full h-full object-cover"
          />
          <AnimatePresence>
            {isHovered && (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent flex flex-col justify-end p-4"
              >
                <p className="text-white font-bold text-lg">{avatar.title}</p>
                <p className="text-white/80 text-sm mb-2">by {avatar.creator}</p>
                <div className="flex gap-2">
                  <Link to={`/student-showcase?id=${avatar.id}`}>
                    <Button size="sm" variant="secondary">
                      <Star className="w-4 h-4 mr-1" /> View Profile
                    </Button>
                  </Link>
                  <Button size="sm" variant="outline">
                    <ExternalLink className="w-4 h-4 mr-1" /> Portfolio
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="p-3 flex justify-between items-center bg-white/10 backdrop-blur-sm">
          <div className="flex items-center gap-2">
            <Badge variant="secondary" className="bg-yellow-300 text-purple-900">
              <Trophy className="w-3 h-3 mr-1" />
              {avatar.achievement}
            </Badge>
          </div>
          <Badge variant="secondary" className="bg-yellow-300 text-purple-900">
            {avatar.school}
          </Badge>
        </div>
      </Card>
    </motion.div>
  )
}

export default function FunAvatarShowcase() {
  const [searchQuery, setSearchQuery] = useState('')
  const [filter, setFilter] = useState('all')
  
  // Updated avatar data with student information
  const avatars = [
    { id: 1, creator: 'Chi Quantum', title: 'AI Innovator', imageUrl: '/team-member-1.png', achievement: 'Robothon Finalist', school: 'Tech Academy' },
    { id: 2, creator: 'Lee AI Engineer', title: 'Robotics Expert', imageUrl: '/team-member-2.png', achievement: 'Best Project', school: 'Innovation High' },
    { id: 3, creator: 'Alex Quantum', title: 'ML Researcher', imageUrl: '/team-member-3.png', achievement: 'AI Challenge Winner', school: 'Quantum School' },
    { id: 4, creator: 'Sarah Chen', title: 'Tech Pioneer', imageUrl: '/team-member-4.png', achievement: 'Rising Star', school: 'Future Academy' }
  ]

  const filteredAvatars = avatars.filter(avatar => 
    avatar.creator.toLowerCase().includes(searchQuery.toLowerCase()) ||
    avatar.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    avatar.school.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <div className="min-h-screen bg-background text-foreground relative overflow-hidden">
      <SpaceBackground />
      
      <div className="relative z-10">
        <header className="bg-purple-900/80 backdrop-blur-sm sticky top-0 z-50 border-b border-yellow-300">
          <div className="container mx-auto px-4 py-4">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <Globe className="w-8 h-8 text-yellow-300" />
                <h1 className="text-3xl font-bold text-yellow-300">Student Showcase</h1>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <div className="relative flex-1">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-300 w-4 h-4" />
                <Input
                  placeholder="Search students..."
                  className="pl-10 bg-purple-800/50 text-yellow-100 placeholder-purple-300 border-purple-600"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="border-yellow-300 text-yellow-300">
                    <Filter className="w-4 h-4 mr-2" />
                    Filter
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => setFilter('all')}>All Students</DropdownMenuItem>
                  <DropdownMenuItem onClick={() => setFilter('finalists')}>Robothon Finalists</DropdownMenuItem>
                  <DropdownMenuItem onClick={() => setFilter('winners')}>Award Winners</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </header>

        <main className="container mx-auto px-4 py-8">
          <div className="flex gap-4 mb-6 overflow-x-auto pb-2">
            <Badge variant="secondary" className="cursor-pointer bg-yellow-300 text-purple-900">All</Badge>
            <Badge variant="outline" className="cursor-pointer text-yellow-300 border-yellow-300">Robothon Finalists</Badge>
            <Badge variant="outline" className="cursor-pointer text-yellow-300 border-yellow-300">AI Innovators</Badge>
            <Badge variant="outline" className="cursor-pointer text-yellow-300 border-yellow-300">Robotics Experts</Badge>
            <Badge variant="outline" className="cursor-pointer text-yellow-300 border-yellow-300">Award Winners</Badge>
          </div>

          <motion.div 
            layout
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
          >
            <AnimatePresence>
              {filteredAvatars.map(avatar => (
                <AvatarCard 
                  key={avatar.id} 
                  avatar={avatar}
                />
              ))}
            </AnimatePresence>
          </motion.div>

          {filteredAvatars.length === 0 && (
            <div className="text-center py-12">
              <p className="text-lg text-yellow-300">No students found matching your search.</p>
            </div>
          )}
        </main>
      </div>
    </div>
  )
}