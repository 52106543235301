'use client'

import React, { useState, useEffect } from 'react'
import { motion, useAnimate } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Button } from "../components/ui/Button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../components/ui/Card"
import { Badge } from "../components/ui/Badge"
import { Progress } from "../components/ui/Progress"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/Tabs"
import { Trophy, Rocket, Brain, Zap, Star, Globe, ExternalLink, Menu, X, Home } from 'lucide-react'

const FloatingMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <motion.div
        initial={false}
        animate={isOpen ? "open" : "closed"}
        className="relative"
      >
        {/* Menu Items */}
        <motion.div
          variants={{
            open: { opacity: 1, y: 0 },
            closed: { opacity: 0, y: 20 }
          }}
          transition={{ duration: 0.2 }}
          className={`absolute bottom-full right-0 mb-2 ${isOpen ? 'block' : 'hidden'}`}
        >
          <div className="bg-dark-blue/90 rounded-lg shadow-lg p-2 space-y-2 min-w-[200px]">
            <Link to="/" className="flex items-center gap-2 text-white hover:bg-primary/20 p-2 rounded-lg transition-colors">
              <Home size={18} />
              Pythaverse Home
            </Link>
            <Link to="/schools" className="flex items-center gap-2 text-white hover:bg-primary/20 p-2 rounded-lg transition-colors">
              <Globe size={18} />
              Schools Directory
            </Link>
            <div className="border-t border-white/10 my-2"></div>
            <div className="px-2 py-1">
              <p className="text-xs text-white/60">Part of Pythaverse Network</p>
            </div>
          </div>
        </motion.div>

        {/* Toggle Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-primary hover:bg-primary/90 text-white p-3 rounded-full shadow-lg transition-colors"
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </motion.div>
    </div>
  );
};

const ParticleAnimation = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {[...Array(50)].map((_, i) => (
        <div
          key={i}
          className="particle"
          style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 2}s`,
            backgroundColor: 'rgba(0, 112, 192, 0.3)'
          }}
        />
      ))}
    </div>
  );
};

export default function SchoolPythaverseProfile() {
  const [activeTab, setActiveTab] = useState('overview')
  const [scope, animate] = useAnimate()

  // Updated theme colors for better readability and consistency
  const schoolTheme = {
    primary: '#0070C0',
    secondary: '#00BFFF',
    accent: '#FFA500',
    success: '#53EC1B',
    purple: '#8B5CF6',
    pink: '#EC4899',
    background: '#ffffff',
    text: {
      primary: '#1a1a1a',
      secondary: '#4a5568',
      accent: '#0070C0'
    }
  };

  useEffect(() => {
    animate(scope.current, { opacity: 1, y: 0 }, { duration: 0.5 })
  }, [animate, scope])

  const achievements = [
    { title: "Robothon 2023 Champions", icon: <Trophy className="w-6 h-6 text-yellow-400" />, description: "Our team secured first place in the annual Pythaverse Robothon, showcasing exceptional problem-solving skills and innovative thinking." },
    { title: "Most Innovative AI Project", icon: <Brain className="w-6 h-6 text-blue-400" />, description: "Recognized for developing a groundbreaking AI model that addresses real-world challenges in environmental conservation." },
    { title: "Community Impact Award", icon: <Star className="w-6 h-6 text-purple-400" />, description: "Honored for our outreach programs that bring coding education to underserved communities, inspired by Pythaverse's mission." },
  ]

  const projects = [
    { name: "EcoBot", description: "An AI-powered robot designed to clean and monitor ocean pollution.", progress: 85 },
    { name: "QuantumLearn", description: "A quantum computing simulation platform for educational purposes.", progress: 70 },
    { name: "NeurAugment", description: "An augmented reality app that visualizes neural networks in real-time.", progress: 90 },
  ]

  // Add tracking for tab changes
  const handleTabChange = (value) => {
    setActiveTab(value);
    window.gtag?.('event', 'tab_change', {
      tab_name: value,
      page: 'school_profile'
    });
  };

  // Add tracking for website visits
  const handleWebsiteVisit = () => {
    window.gtag?.('event', 'school_website_visit', {
      school_name: 'Quantum Academy'
    });
  };

  return (
    <div 
      className="min-h-screen relative overflow-hidden"
      style={{ 
        background: schoolTheme.background,
        color: schoolTheme.text.primary
      }}
    >
      <ParticleAnimation />
      
      <div className="relative z-10">
        {/* Enhanced header with gradient */}
        <header className="bg-gradient-to-r from-primary via-secondary to-accent sticky top-0 text-white">
          <div className="container mx-auto px-4 py-6">
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-3xl font-bold">Quantum Academy</h1>
                <p className="text-white/90">Excellence in Technology Education</p>
              </div>
              <Button 
                variant="outline" 
                className="border-white text-white hover:bg-white hover:text-primary"
                onClick={handleWebsiteVisit}
              >
                <Globe className="w-4 h-4 mr-2" />
                Visit School Website
              </Button>
            </div>
          </div>
        </header>

        {/* Enhanced hero banner with dynamic gradient overlay */}
        <div className="relative h-[400px] bg-cover bg-center" style={{ backgroundImage: 'url("/school-banner.png")' }}>
          <div className="absolute inset-0 bg-gradient-to-r from-primary/90 via-secondary/80 to-transparent">
            <div className="container mx-auto px-4 h-full flex items-center">
              <div className="max-w-2xl text-white">
                <div className="mb-4 inline-block px-4 py-2 bg-white/10 backdrop-blur rounded-lg border border-white/20">
                  <span className="text-sm font-semibold">Featured Pythaverse Network School</span>
                </div>
                <h2 className="text-4xl font-bold mb-4 drop-shadow-lg">Shaping Tomorrow's Innovators</h2>
                <p className="text-xl opacity-90 drop-shadow">Join us in our journey of technological excellence and innovation</p>
              </div>
            </div>
          </div>
        </div>

        <main className="container mx-auto px-4 py-8">
          {/* Enhanced badges section with gradients */}
          <motion.section 
            ref={scope}
            initial={{ opacity: 0, y: 20 }}
            className="mb-16 text-center"
          >
            <div className="flex justify-center space-x-4">
              <Badge variant="secondary" className="text-lg py-1 px-3 bg-gradient-to-r from-primary to-secondary text-white">
                <Rocket className="w-5 h-5 mr-2" />
                Robothon Champions
              </Badge>
              <Badge variant="secondary" className="text-lg py-1 px-3 bg-gradient-to-r from-secondary to-success text-white">
                <Zap className="w-5 h-5 mr-2" />
                AI Innovators
              </Badge>
              <Badge variant="secondary" className="text-lg py-1 px-3 bg-gradient-to-r from-accent to-pink text-white">
                <Star className="w-5 h-5 mr-2" />
                Community Leaders
              </Badge>
            </div>
          </motion.section>

          {/* Enhanced tabs with consistent colors */}
          <Tabs defaultValue="overview" className="w-full" onValueChange={handleTabChange}>
            <TabsList className="grid w-full grid-cols-3 mb-8 bg-gray-100 p-1 rounded-lg">
              <TabsTrigger 
                value="overview" 
                className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
              >
                Overview
              </TabsTrigger>
              <TabsTrigger 
                value="achievements" 
                className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
              >
                Achievements
              </TabsTrigger>
              <TabsTrigger 
                value="projects" 
                className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
              >
                Projects
              </TabsTrigger>
            </TabsList>

            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <TabsContent value="overview">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <Card className="bg-white shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300">
                    <CardHeader className="border-b border-gray-100">
                      <CardTitle className="text-gray-900 flex items-center">
                        <Brain className="w-6 h-6 mr-2 text-primary" />
                        Our Pythaverse Journey
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="pt-6">
                      <p className="text-gray-700 mb-4 leading-relaxed">At Quantum Academy, we've embraced the Pythaverse ecosystem to push the boundaries of what's possible in technology education. Our journey has been marked by innovation, collaboration, and a commitment to excellence.</p>
                      <p className="text-gray-700 leading-relaxed">Through Pythaverse and Robothon, our students have gained hands-on experience in cutting-edge technologies, developed critical problem-solving skills, and connected with a global community of tech enthusiasts.</p>
                    </CardContent>
                  </Card>

                  <Card className="bg-white shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300">
                    <CardHeader className="border-b border-gray-100">
                      <CardTitle className="text-gray-900 flex items-center">
                        <Trophy className="w-6 h-6 mr-2 text-primary" />
                        Impact Metrics
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="pt-6 space-y-6">
                      <div>
                        <p className="font-semibold mb-2 text-gray-800">Students Impacted</p>
                        <Progress value={80} className="h-2 bg-gray-200" barClassName="bg-primary" />
                        <p className="text-sm text-right text-gray-600 mt-1">800+</p>
                      </div>
                      <div>
                        <p className="font-semibold mb-2 text-gray-800">Projects Completed</p>
                        <Progress value={65} className="h-2 bg-gray-200" barClassName="bg-secondary" />
                        <p className="text-sm text-right text-gray-600 mt-1">50+</p>
                      </div>
                      <div>
                        <p className="font-semibold mb-2 text-gray-800">Robothon Participations</p>
                        <Progress value={100} className="h-2 bg-gray-200" barClassName="bg-success" />
                        <p className="text-sm text-right text-gray-600 mt-1">5 Years</p>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </TabsContent>

              <TabsContent value="achievements">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {achievements.map((achievement, index) => (
                    <Card key={index} className="bg-white shadow-lg border border-gray-100">
                      <CardHeader>
                        <CardTitle className="text-gray-900 flex items-center">
                          {achievement.icon}
                          <span className="ml-2">{achievement.title}</span>
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-gray-700 leading-relaxed">{achievement.description}</p>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabsContent>

              <TabsContent value="projects">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {projects.map((project, index) => (
                    <Card key={index} className="bg-white shadow-lg border border-gray-100">
                      <CardHeader>
                        <CardTitle className="text-gray-900">{project.name}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-gray-700 mb-4 leading-relaxed">{project.description}</p>
                        <div>
                          <p className="font-semibold mb-2 text-gray-800">Progress</p>
                          <Progress value={project.progress} className="h-2 bg-gray-200" barClassName="bg-primary" />
                          <p className="text-sm text-right text-gray-600 mt-1">{project.progress}%</p>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabsContent>
            </motion.div>
          </Tabs>

          {/* Enhanced team section */}
          <section id="team" className="mt-16">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Meet Our Robothon Team</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
              {[1, 2, 3, 4].map((member) => (
                <Card key={member} className="bg-white shadow-lg border border-gray-100 hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
                  <CardContent className="pt-6">
                    <div className="w-24 h-24 rounded-full bg-gradient-to-br from-primary to-secondary mx-auto mb-4 relative overflow-hidden">
                      <img 
                        src={`/team-member-${member}.png`}
                        alt={`Team Member ${member}`}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                    </div>
                    <h3 className="text-lg font-semibold text-center text-gray-900">Team Member {member}</h3>
                    <p className="text-sm text-center text-gray-600">Role</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section>
        </main>

        {/* Enhanced footer with gradient */}
        <footer className="bg-gradient-to-r from-primary via-secondary to-accent py-8 mt-12 text-white">
          <div className="container mx-auto px-4 text-center">
            <img src="/school-logo.png" alt="Quantum Academy" className="h-16 mx-auto mb-4" />
            <p className="text-white/90">&copy; 2024 Quantum Academy. All rights reserved.</p>
            <p className="text-white/80 text-sm mt-2">A proud member of the Pythaverse Network</p>
          </div>
        </footer>
      </div>

      <FloatingMenu />
    </div>
  );
}