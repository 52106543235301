import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navigation from './components/shared/Navigation';
import Home from './pages/Home';
import Schools from './pages/Schools';
import Students from './pages/Students';
import Parents from './pages/Parents';
import Media from './pages/Media';
import StudentShowcase from './pages/student-showcase';
import StudentShowcase1 from './pages/student-showcase1';
import PartnerSummit from './pages/PartnerSummit';
import SchoolPythaverseProfile from './pages/school-pythaverse-profile';
import SchoolPythaverseProfileV2 from './pages/school-pythaverse-profile-v2';
import SchoolPythaverseProfileCustomizable from './pages/school-pythaverse-profile-customizable';
import FunAvatarShowcase from './pages/fun-avatar-showcase';
import GoogleAnalytics from './components/GoogleAnalytics';

// Wrapper component to conditionally render Navigation
function NavigationWrapper() {
  const location = useLocation();
  
  // List of routes where we don't want to show the main navigation
  const hideNavigationRoutes = [
    '/school-pythaverse-profile',
    '/school-pythaverse-profile-v2',
    '/school-pythaverse-profile-customizable'
  ];

  // Check if current path is in the list of routes where navigation should be hidden
  const shouldShowNavigation = !hideNavigationRoutes.includes(location.pathname);

  return shouldShowNavigation ? <Navigation /> : null;
}

function App() {
  return (
    <Router>
      <GoogleAnalytics />
      <NavigationWrapper />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/students" element={<Students />} />
        <Route path="/parents" element={<Parents />} />
        <Route path="/media" element={<Media />} />
        <Route path="/student-showcase" element={<StudentShowcase />} />
        <Route path="/student-showcase1" element={<StudentShowcase1 />} />
        <Route path="/fun-avatar-showcase" element={<FunAvatarShowcase />} />
        <Route path="/partner-summit" element={<PartnerSummit />} />
        <Route path="/school-pythaverse-profile" element={<SchoolPythaverseProfile />} />
        <Route path="/school-pythaverse-profile-v2" element={<SchoolPythaverseProfileV2 />} />
        <Route path="/school-pythaverse-profile-customizable" element={<SchoolPythaverseProfileCustomizable />} />
      </Routes>
    </Router>
  );
}

export default App; 