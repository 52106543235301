import React from 'react';

export function Card({ children, className = '', ...props }) {
  return (
    <div className={`bg-white shadow-lg border border-gray-200 hover:shadow-xl rounded-lg transition-all duration-300 ${className}`} {...props}>
      {children}
    </div>
  );
}

export function CardHeader({ children, className = '', ...props }) {
  return <div className={`p-6 border-b border-gray-100 ${className}`} {...props}>{children}</div>;
}

export function CardTitle({ children, className = '', ...props }) {
  return <h3 className={`text-xl font-bold text-gray-900 ${className}`} {...props}>{children}</h3>;
}

export function CardContent({ children, className = '', ...props }) {
  return <div className={`p-6 text-gray-700 ${className}`} {...props}>{children}</div>;
} 