import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function Schools() {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const bannerData = [
    {
      image: "/classic-profile.png",
      title: "Classic Profile Style",
      description: "Traditional layout with modern animations",
      style: "from-blue-900/50 to-blue-600/30",
      link: "/school-pythaverse-profile",
      buttonText: "View Classic Profile"
    },
    {
      image: "/modern-profile.png",
      title: "Modern Profile Style",
      description: "Contemporary design with advanced features",
      style: "from-purple-900/50 to-purple-600/30",
      link: "/school-pythaverse-profile-v2",
      buttonText: "View Modern Profile"
    },
    {
      image: "/custom-profile.png",
      title: "Customizable Profile Style",
      description: "Fully customizable with your school's branding",
      style: "from-emerald-900/50 to-emerald-600/30",
      link: "/school-pythaverse-profile-customizable",
      buttonText: "View Custom Profile"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % bannerData.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <section className="py-20 bg-gradient-to-b from-dark-blue/50 via-dark to-dark-blue/80">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-4 text-white">Schools Participation</h1>
          <p className="text-xl text-gray-300 mb-8">Join Pythaverse Robothon 2024 and showcase your school's innovation by creating your school's profile on Pythaverse</p>

          {/* New Banner Carousel */}
          <div className="relative h-[500px] mb-16 rounded-2xl overflow-hidden">
            <AnimatePresence initial={false} mode="wait">
              <motion.div
                key={currentSlide}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="absolute inset-0"
              >
                <div 
                  className="relative w-full h-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${bannerData[currentSlide].image})` }}
                >
                  {/* Gradient Overlay */}
                  <div className={`absolute inset-0 bg-gradient-to-r ${bannerData[currentSlide].style} opacity-90`}></div>
                  
                  {/* Content */}
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="text-center max-w-3xl px-4">
                      <motion.h2
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        className="text-5xl font-bold mb-4 text-white"
                      >
                        {bannerData[currentSlide].title}
                      </motion.h2>
                      <motion.p
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="text-xl text-gray-200 mb-8"
                      >
                        {bannerData[currentSlide].description}
                      </motion.p>
                      <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                      >
                        <Link 
                          to={bannerData[currentSlide].link}
                          className="inline-block px-8 py-3 bg-white/10 backdrop-blur-sm border border-white/30 rounded-full text-white hover:bg-white/20 transition-all duration-300 transform hover:scale-105"
                        >
                          {bannerData[currentSlide].buttonText}
                        </Link>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>

            {/* Slide Indicators */}
            <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex gap-2">
              {bannerData.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    currentSlide === index 
                      ? 'bg-white w-8' 
                      : 'bg-white/50 hover:bg-white/70'
                  }`}
                />
              ))}
            </div>
          </div>

          {/* Rest of your existing content */}
          
            <div className="bg-gradient-to-r from-primary/80 via-primary/60 to-secondary/50 backdrop-blur-sm p-8 rounded-lg mb-12 shadow-xl">
              <h2 className="text-3xl font-bold mb-4">Ready to Showcase Your School?</h2>
              <p className="text-xl mb-6">Register now into our wait list to create your school's digital presence in the Pythaverse ecosystem</p>
              <a 
                href="https://forms.gle/8qKRbZWcy73798HH8" 
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-white hover:bg-gray-100 text-primary py-3 px-8 rounded-full text-lg font-bold transition-all duration-300 transform hover:scale-105 shadow-lg"
              >
                Register Your School
              </a>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="bg-dark/80 backdrop-blur-sm border border-primary/20 rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
              <h2 className="text-2xl font-bold mb-4">Digital Twin Space</h2>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-primary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p className="mb-4 text-gray-300">Create a virtual representation of your school to showcase projects and achievements.</p>
              <a 
                href="https://forms.gle/8qKRbZWcy73798HH8" 
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block w-full bg-primary/80 hover:bg-primary text-white py-2 px-4 rounded-lg text-center transition-colors duration-300"
              >
                Get your free Digital Twin Space
              </a>
            </div>

            <div className="bg-dark/80 backdrop-blur-sm border border-primary/20 rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
              <h2 className="text-2xl font-bold mb-4">Student Showcase</h2>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-secondary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <p className="mb-4">Highlight your students' innovative projects and robotics achievements.</p>
              <a 
                href="https://forms.gle/8qKRbZWcy73798HH8" 
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block w-full bg-primary hover:bg-primary/90 text-white py-2 px-4 rounded text-center"
              >
                Showcase your Student Projects
              </a>
            </div>

            <div className="bg-dark/80 backdrop-blur-sm border border-primary/20 rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
              <h2 className="text-2xl font-bold mb-4">Workshop Access</h2>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-secondary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <p className="mb-4">Gain access to exclusive workshops and training sessions for educators.</p>
              <button disabled className="inline-block w-full bg-gray-500 cursor-not-allowed text-white py-2 px-4 rounded text-center opacity-50">
                Register for Workshops (Coming soon)
              </button>
            </div>
          </div>

          <div className="bg-dark/80 backdrop-blur-sm border border-primary/20 rounded-lg p-8 shadow-lg">
            <h2 className="text-2xl font-bold mb-6">Resources for Schools</h2>
            <div className="space-y-4">
              <a href="#" className="block w-full bg-primary/20 hover:bg-primary/30 text-white py-3 px-6 rounded-lg text-left transition-colors duration-300 flex items-center">
                <span className="mr-2">📚</span>
                Event Guidelines for Schools
              </a>
              <a href="#" className="block w-full bg-primary/20 hover:bg-primary/30 text-white py-3 px-6 rounded-lg text-left transition-colors duration-300 flex items-center">
                <span className="mr-2">✅</span>
                Preparation Checklist
              </a>
              <a href="#" className="block w-full bg-primary/20 hover:bg-primary/30 text-white py-3 px-6 rounded-lg text-left transition-colors duration-300 flex items-center">
                <span className="mr-2">🎯</span>
                Promotional Materials
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Schools; 