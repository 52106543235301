import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

function Navigation() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  // Define styles based on the current route
  const linkStyle = "text-white hover:text-secondary transition-colors duration-300";
  
  // Track navigation events
  const handleNavClick = (destination) => {
    setIsMenuOpen(false);
    window.gtag?.('event', 'navigation_click', {
      destination: destination,
      from_page: location.pathname
    });
  };

  // Track page views
  useEffect(() => {
    window.gtag?.('event', 'page_view', {
      page_title: document.title,
      page_path: location.pathname + location.search,
      page_location: window.location.href
    });
  }, [location]);
  
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-[#0070C0] border-b border-[#0070C0]/50 shadow-lg">
      <div className="container mx-auto px-4">
        {/* Desktop and Mobile Header */}
        <div className="h-16 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Link 
              to="/" 
              className="flex items-center space-x-2"
              onClick={() => handleNavClick('home')}
            >
              <img src="/logo.png" alt="Pythaverse" className="h-10" />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-4">
            <Link 
              to="/" 
              className={linkStyle}
              onClick={() => handleNavClick('home')}
            >
              Home
            </Link>
            <Link 
              to="/media" 
              className={linkStyle}
              onClick={() => handleNavClick('media')}
            >
              Media
            </Link>
            <Link 
              to="/schools" 
              className={linkStyle}
              onClick={() => handleNavClick('schools')}
            >
              Schools
            </Link>
            <Link 
              to="/students" 
              className={linkStyle}
              onClick={() => handleNavClick('students')}
            >
              Students
            </Link>
           
            <Link 
              to="/parents" 
              className="bg-[#FE5B00] hover:bg-[#FE5B00]/90 text-white py-2 px-4 rounded-full text-sm font-semibold transition-colors"
              onClick={() => handleNavClick('parents')}
            >
              For Parents
            </Link>
            <Link 
              to="/partner-summit" 
              className="bg-secondary hover:bg-secondary/90 text-black py-2 px-4 rounded-full text-sm font-semibold transition-colors"
              onClick={() => handleNavClick('partner_summit')}
            >
              Partner Summit
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
              window.gtag?.('event', 'mobile_menu_toggle', {
                action: isMenuOpen ? 'close' : 'open'
              });
            }}
            className="md:hidden p-2 text-white hover:bg-[#0070C0]/80 rounded-lg transition-colors"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile Navigation */}
        <div 
          className={`
            md:hidden 
            absolute left-0 right-0 
            bg-[#0070C0] 
            shadow-lg 
            transition-all duration-300 ease-in-out
            ${isMenuOpen ? 'max-h-screen py-4' : 'max-h-0 overflow-hidden'}
          `}
        >
          <div className="flex flex-col space-y-4 px-4">
            {/* Mobile menu links with tracking */}
            {[
              { to: '/', label: 'Home', tracking: 'home' },
              { to: '/media', label: 'Media', tracking: 'media' },
              { to: '/schools', label: 'Schools', tracking: 'schools' },
              { to: '/students', label: 'Students', tracking: 'students' },
              { to: '/fun-avatar-showcase', label: 'Avatar Showcase', tracking: 'fun_avatar_showcase' },
              { to: '/parents', label: 'For Parents', tracking: 'parents', className: "bg-[#FE5B00] hover:bg-[#FE5B00]/90 text-white py-2 px-4 rounded-full text-sm font-semibold transition-colors text-center" },
              { to: '/partner-summit', label: 'Partner Summit', tracking: 'partner_summit', className: "bg-secondary hover:bg-secondary/90 text-black py-2 px-4 rounded-full text-sm font-semibold transition-colors text-center" }
            ].map(({ to, label, tracking, className }) => (
              <Link 
                key={to}
                to={to} 
                className={className || linkStyle}
                onClick={() => handleNavClick(tracking)}
              >
                {label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;