import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Media.module.css';

function Media() {
  return (
    <div className={styles.container}>
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h1 className={`text-4xl font-bold mb-4 ${styles.title}`}>Media Resources</h1>
          <p className={`text-xl mb-8 ${styles.subtitle}`}>
            Access everything you need to cover Pythaverse Robothon 2024
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className={styles.card}>
              <h2 className={`text-2xl font-bold mb-4 ${styles.title}`}>Press Kit</h2>
              <div className="space-y-4">
                <button className={`w-full py-2 px-4 rounded flex items-center justify-start ${styles.downloadButton}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  Event Overview
                </button>
                <button className={`w-full py-2 px-4 rounded flex items-center justify-start ${styles.downloadButton}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  Speaker Profiles
                </button>
                <button className={`w-full py-2 px-4 rounded flex items-center justify-start ${styles.downloadButton}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  High-Res Images
                </button>
                <button className={`w-full py-2 px-4 rounded flex items-center justify-start ${styles.downloadButton}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  B-Roll Footage
                </button>
              </div>
            </div>

            <div className={styles.card}>
              <h2 className={`text-2xl font-bold mb-4 ${styles.title}`}>Media Guidelines</h2>
              <div className="space-y-4">
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 mr-2 ${styles.icon}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span className={styles.guidelineText}>Photography permitted in designated areas</span>
                </div>
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 mr-2 ${styles.icon}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                  </svg>
                  <span className={styles.guidelineText}>Video recording allowed during specified sessions</span>
                </div>
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 mr-2 ${styles.icon}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                  </svg>
                  <span className={styles.guidelineText}>Interview opportunities available upon request</span>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.card} mb-12`}>
            <h2 className={`text-2xl font-bold mb-4 ${styles.title}`}>Live Coverage Support</h2>
            <p className={`mb-4 ${styles.guidelineText}`}>
              Access our real-time event feed and streaming services for up-to-the-minute coverage.
            </p>
            <a href="#" className={`inline-block py-2 px-4 rounded ${styles.primaryButton}`}>
              Access Live Stream
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline-block ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
          </div>

          <div className={styles.card}>
            <h2 className={`text-2xl font-bold mb-4 ${styles.title}`}>Media Registration</h2>
            <p className={`mb-4 ${styles.guidelineText}`}>
              Register as media to receive exclusive updates and access to press conferences.
            </p>
            <a 
              href="https://forms.gle/XxvN8GZ5w73adyRw6" 
              target="_blank" 
              rel="noopener noreferrer" 
              className={`inline-block py-2 px-4 rounded ${styles.secondaryButton}`}
            >
              Register as Media
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline-block ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Media;