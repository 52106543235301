'use client'

import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Button } from '../components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/Card"
import { Badge } from "../components/ui/Badge"
import { Progress } from "../components/ui/Progress"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/Tabs"
import { Trophy, Rocket, Brain, Zap, Star, Book, Code, Award, Github, Linkedin, Download, ExternalLink } from 'lucide-react'
import styles from './student-showcase.module.css'

const TimelineEvent = ({ year, title, description }) => (
  <div className="mb-8 flex justify-between items-center w-full right-timeline">
    <div className="order-1 w-5/12"></div>
    <div className="z-20 flex items-center justify-center order-1 bg-gray-800 shadow-xl w-16 h-16 rounded-full">
      <h1 className="mx-auto text-lg font-semibold text-white">{year}</h1>
    </div>
    <div className="order-1 bg-white rounded-lg shadow-xl w-5/12 px-6 py-4">
      <h3 className="mb-3 font-bold text-gray-800 text-xl">{title}</h3>
      <p className="text-sm leading-snug tracking-wide text-gray-600">{description}</p>
    </div>
  </div>
)

export default function StudentShowcase() {
  const [activeTab, setActiveTab] = useState('journey')

  const skills = [
    { name: "Python", level: 90 },
    { name: "Machine Learning", level: 75 },
    { name: "Robotics", level: 85 },
    { name: "Data Analysis", level: 80 },
    { name: "Web Development", level: 70 },
  ]

  const projects = [
    { name: "EcoSense", description: "An IoT-based environmental monitoring system", tech: ["Python", "Raspberry Pi", "Machine Learning"] },
    { name: "RoboAssist", description: "A voice-controlled robotic assistant for elderly care", tech: ["Python", "TensorFlow", "Robotics"] },
    { name: "DataViz Dashboard", description: "Interactive data visualization platform for Robothon results", tech: ["React", "D3.js", "Node.js"] },
  ]

  return (
    <div className={styles.container}>
      <div className="pt-20">
        <header className={styles.header}>
          <nav className="container mx-auto px-4 py-4 flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-gray-800">Pythaverse Showcase</Link>
            <div className="space-x-4">
              <Link to="#journey" className="text-gray-600 hover:text-gray-900 transition-colors">Journey</Link>
              <Link to="#skills" className="text-gray-600 hover:text-gray-900 transition-colors">Skills</Link>
              <Link to="#projects" className="text-gray-600 hover:text-gray-900 transition-colors">Projects</Link>
              <Link to="#certificate" className="text-gray-600 hover:text-gray-900 transition-colors">Certificate</Link>
            </div>
          </nav>
        </header>

        <main className="container mx-auto px-4 py-8">
          <motion.section 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-16 text-center"
          >
            <div className="flex flex-col md:flex-row items-center justify-center gap-8 mb-8">
              <div className="relative w-64 h-64">
                <div className="absolute inset-0 bg-gradient-to-br from-gray-200 to-gray-300 rounded-full"></div>
                <img src="/team-member-1.png" alt="AI-generated Avatar" className="absolute inset-2 rounded-full object-cover" />
              </div>
              <div className="text-left">
                <h1 className="text-4xl font-bold mb-4 text-gray-900">Chi Quantum</h1>
                <p className="text-xl mb-4 text-gray-600">Aspiring AI Engineer | Robothon Finalist</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  <Badge variant="outline">Python</Badge>
                  <Badge variant="outline">Machine Learning</Badge>
                  <Badge variant="outline">Robotics</Badge>
                  <Badge variant="outline">Data Science</Badge>
                </div>
                <div className="flex gap-4">
                  <Button variant="outline" size="icon">
                    <Github className="h-4 w-4" />
                  </Button>
                  <Button variant="outline" size="icon">
                    <Linkedin className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          </motion.section>

          <Tabs defaultValue="journey" className="w-full" onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-4 mb-8">
              <TabsTrigger value="journey">STEM Journey</TabsTrigger>
              <TabsTrigger value="skills">Skills</TabsTrigger>
              <TabsTrigger value="projects">Projects</TabsTrigger>
              <TabsTrigger value="certificate">Certificate</TabsTrigger>
            </TabsList>
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <TabsContent value="journey">
                <div className="container mx-auto w-full h-full">
                  <div className="relative wrap overflow-hidden p-10 h-full">
                    <div className="border-2-2 absolute border-opacity-20 border-gray-300 h-full border" style={{left: '50%'}}></div>
                    <TimelineEvent 
                      year="2020"
                      title="Discovered Pythaverse"
                      description="Began my STEM journey with Pythaverse, learning Python and basic programming concepts."
                      yearClassName="w-24 h-24 flex items-center justify-center" // Made circle bigger
                    />
                    <TimelineEvent 
                      year="2021"
                      title="First Robothon"
                      description="Participated in my first Robothon, reaching the semi-finals with a smart home automation project."
                      yearClassName="w-24 h-24 flex items-center justify-center"
                    />
                    <TimelineEvent 
                      year="2022"
                      title="AI & Machine Learning"
                      description="Delved into AI and Machine Learning through Pythaverse advanced courses, applying knowledge to real-world problems."
                      yearClassName="w-24 h-24 flex items-center justify-center"
                    />
                    <TimelineEvent 
                      year="2023"
                      title="Robothon Finalist"
                      description="Made it to the Robothon finals with an AI-powered environmental monitoring system, EcoSense."
                      yearClassName="w-24 h-24 flex items-center justify-center"
                    />
                    <TimelineEvent 
                      year="2024"
                      title="Mentorship & Future Goals"
                      description="Currently mentoring junior Pythaverse students and preparing for university studies in AI and Robotics."
                      yearClassName="w-24 h-24 flex items-center justify-center"
                    />
                  </div>
                </div>
              </TabsContent>
              <TabsContent value="skills">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {skills.map((skill, index) => (
                    <Card key={index}>
                      <CardHeader>
                        <CardTitle className="text-gray-800">{skill.name}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="space-y-2">
                          <Progress value={skill.level} className="w-full" />
                          <p className="text-sm text-right text-gray-600">{skill.level}%</p>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabsContent>
              <TabsContent value="projects">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {projects.map((project, index) => (
                    <Card key={index}>
                      <CardHeader>
                        <CardTitle className="flex items-center text-gray-800">
                          <Rocket className="w-5 h-5 mr-2 text-gray-600" />
                          {project.name}
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="mb-4 text-gray-600">{project.description}</p>
                        <div className="flex flex-wrap gap-2">
                          {project.tech.map((tech, i) => (
                            <Badge key={i} variant="outline">{tech}</Badge>
                          ))}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabsContent>
              <TabsContent value="certificate">
                <div className="flex flex-col items-center">
                  <Card className="w-full max-w-3xl">
                    <CardHeader>
                      <CardTitle className="flex items-center justify-center text-gray-800">
                        <Award className="w-6 h-6 mr-2 text-gray-600" />
                        Pythaverse Robothon Achievement
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <img src="/opencerts-demo.jpg" alt="Open Cert Certificate" className="w-full h-auto rounded-lg shadow-lg mb-4" />
                      <p className="text-center mb-4 text-gray-600">This certificate is awarded to <strong>Alex Quantum</strong> for outstanding achievement in the Pythaverse Robothon 2023.</p>
                      <div className="flex justify-center space-x-4">
                        <Button variant="outline">
                          <Download className="w-4 h-4 mr-2" />
                          Download Certificate
                        </Button>
                        <Button variant="outline">
                          <ExternalLink className="w-4 h-4 mr-2" />
                          Verify on OpenCert
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </TabsContent>
            </motion.div>
          </Tabs>
        </main>

        <footer className={styles.footer}>
          <div className="container mx-auto px-4 text-center">
            <p className="text-gray-600">&copy; 2024 Pythaverse. All rights reserved.</p>
            <p className="mt-2 text-sm text-gray-500">Empowering the next generation of STEM innovators</p>
          </div>
        </footer>
      </div>
    </div>
  )
}