import React from 'react';

export function Progress({ value = 0, className = '', barClassName = '', ...props }) {
  return (
    <div className={`h-2 w-full bg-gray-100 rounded-full overflow-hidden ${className}`} {...props}>
      <div 
        className={`h-full bg-gray-700 transition-all duration-500 rounded-full ${barClassName}`}
        style={{ width: `${value}%` }}
      />
    </div>
  );
} 