import React from 'react';

export function Input({ className = '', ...props }) {
  return (
    <input
      className={`
        flex h-10 w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm 
        placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 
        focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
        dark:border-gray-700 dark:bg-gray-800 dark:text-gray-50 dark:focus:ring-gray-400 
        dark:focus:ring-offset-gray-900
        ${className}
      `}
      {...props}
    />
  );
} 