import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Students() {
  // Updated avatar data with team member images
  const avatars = [
    { id: 1, image: '/team-member-1.png', name: 'Tech Pioneer' },
    { id: 2, image: '/team-member-2.png', name: 'AI Innovator' },
    { id: 3, image: '/team-member-3.png', name: 'Quantum Warrior' },
    { id: 4, image: '/team-member-4.png', name: 'Digital Explorer' },
    // Repeat the same images for continuous scroll
    { id: 5, image: '/team-member-1.png', name: 'Tech Pioneer' },
    { id: 6, image: '/team-member-2.png', name: 'AI Innovator' },
    { id: 7, image: '/team-member-3.png', name: 'Quantum Warrior' },
    { id: 8, image: '/team-member-4.png', name: 'Digital Explorer' }
  ];

  return (
    <>
      <section className="py-20 bg-gradient-to-br from-dark-blue/80 via-purple-900/50 to-dark-blue/80">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-4 text-white">Student Participation</h1>
          <p className="text-xl text-gray-300 mb-8">Join Pythaverse Robothon 2024 and showcase your robotics skills</p>

          {/* Avatar Showcase Banner */}
          <div className="bg-gradient-to-r from-blue-900/50 via-purple-900/50 to-blue-900/50 backdrop-blur-sm p-8 rounded-lg mb-12 shadow-xl border border-white/10">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-2xl font-bold text-white mb-2">AI-Generated Avatars</h2>
                <p className="text-gray-300">Create your unique digital identity for Robothon 2024</p>
              </div>
              <Link 
                to="/fun-avatar-showcase"
                className="bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white px-6 py-3 rounded-full font-bold transition-all duration-300 transform hover:scale-105 shadow-lg"
              >
                See All Avatars
              </Link>
            </div>
            
            <div className="relative overflow-hidden h-48">
              <motion.div 
                className="flex gap-4 absolute"
                animate={{ x: [0, -1920] }}
                transition={{ 
                  duration: 20,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                {avatars.map((avatar, index) => (
                  <div 
                    key={`${avatar.id}-${index}`} 
                    className="w-40 flex-shrink-0"
                  >
                    <div className="relative w-40 h-40 rounded-lg overflow-hidden bg-gradient-to-br from-purple-500/20 to-blue-500/20 backdrop-blur-sm border border-white/20">
                      <img 
                        src={avatar.image} 
                        alt={avatar.name}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.target.src = "/avatar-placeholder.svg";
                        }}
                      />
                      <div className="absolute bottom-0 inset-x-0 bg-gradient-to-t from-black/80 to-transparent p-2">
                        <p className="text-sm text-center text-white">{avatar.name}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </motion.div>
            </div>
          </div>

          {/* Example Showcase Banner */}
          <div className="bg-gradient-to-r from-primary/20 via-purple-500/20 to-secondary/20 backdrop-blur-sm p-8 rounded-lg mb-12 shadow-xl border border-white/10">
            <h2 className="text-2xl font-bold mb-2 text-white">Example Student Showcases</h2>
            <p className="text-gray-300 mb-6">Preview what your profile could look like after registration. These are examples of how you can showcase your journey, skills, and achievements.</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <Link to="/student-showcase" 
                className="block bg-dark/50 border border-purple-500/30 rounded-lg p-6 hover:bg-purple-900/30 transition-all duration-300 group"
              >
                <div>
                  <div className="bg-primary/20 text-primary text-sm font-semibold px-3 py-1 rounded-full inline-block mb-4">Example Profile 1</div>
                  <h3 className="text-xl font-bold mb-2">Chi Quantum</h3>
                  <p className="text-gray-300 mb-4">Aspiring AI Engineer | Robothon Finalist</p>
                  <div className="flex gap-2 mb-4">
                    <span className="px-2 py-1 bg-purple-900/30 text-purple-200 rounded-full text-sm">Python</span>
                    <span className="px-2 py-1 bg-purple-900/30 text-purple-200 rounded-full text-sm">Machine Learning</span>
                    <span className="px-2 py-1 bg-purple-900/30 text-purple-200 rounded-full text-sm">Robotics</span>
                  </div>
                  <span className="text-purple-300 group-hover:translate-x-2 transition-transform inline-block">View Full Profile →</span>
                </div>
              </Link>

              <Link to="/student-showcase1" 
                className="block bg-dark/50 border border-purple-500/30 rounded-lg p-6 hover:bg-purple-900/30 transition-all duration-300 group"
              >
                <div>
                  <div className="bg-primary/20 text-primary text-sm font-semibold px-3 py-1 rounded-full inline-block mb-4">Example Profile 2</div>
                  <h3 className="text-xl font-bold mb-2">Lee AI Engineer</h3>
                  <p className="text-gray-300 mb-4">Future AI and Robotics Engineer</p>
                  <div className="flex gap-2 mb-4">
                    <span className="px-2 py-1 bg-purple-900/30 text-purple-200 rounded-full text-sm">AI Enthusiast</span>
                    <span className="px-2 py-1 bg-purple-900/30 text-purple-200 rounded-full text-sm">Robotics Expert</span>
                    <span className="px-2 py-1 bg-purple-900/30 text-purple-200 rounded-full text-sm">Quantum Computing</span>
                  </div>
                  <span className="text-purple-300 group-hover:translate-x-2 transition-transform inline-block">View Portfolio →</span>
                </div>
              </Link>
            </div>
          </div>

          {/* Registration Call to Action */}
          <div className="bg-gradient-to-r from-primary to-dark-blue p-8 rounded-lg mb-12 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Create Your Profile?</h2>
            <p className="text-xl mb-6">Register now to our wait list to showcase your skills and join the Robothon community</p>
            <a 
              href="https://forms.gle/NiDRgS2Z5oNTbhBD9" 
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-secondary hover:bg-secondary/90 text-black py-3 px-8 rounded-full text-lg font-bold transition-all duration-300 transform hover:scale-105"
            >
              Register Now
            </a>
          </div>

          {/* Original content continues... */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className="bg-dark/50 border border-secondary/30 rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">AI Avatar Generator</h2>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-secondary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
              </svg>
              <p className="mb-4">Create your unique digital identity for the event using our AI-powered avatar generator.</p>
              <a 
                href="https://forms.gle/NiDRgS2Z5oNTbhBD9" 
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block w-full bg-primary hover:bg-primary/90 text-white py-2 px-4 rounded text-center"
              >
                Register to Get your AI Avatar
              </a>
            </div>

            <div className="bg-dark/50 border border-secondary/30 rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Project Showcase</h2>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-secondary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
              </svg>
              <p className="mb-4">Submit your robotics project for a chance to be featured in our online showcase.</p>
              <a href="https://forms.gle/NiDRgS2Z5oNTbhBD9" className="inline-block w-full bg-primary hover:bg-primary/90 text-white py-2 px-4 rounded text-center">
                Submit Your Project
              </a>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className="bg-dark/50 border border-secondary/30 rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Certificate of Participation</h2>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-secondary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <p className="mb-4">Register to get your Certificate of Participation.</p>
              <a href="https://forms.gle/NiDRgS2Z5oNTbhBD9" className="inline-block w-full bg-gray-400 cursor-not-allowed text-white py-2 px-4 rounded text-center" onClick={(e) => e.preventDefault()}>
                Register ( Available soon)
              </a>
            </div>

            <div className="bg-dark/50 border border-secondary/30 rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Competition Guidelines</h2>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-secondary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
              </svg>
              <p className="mb-4">Access detailed guidelines and rules for the robotics competition.</p>
              <a href="https://robothon.asia/" className="inline-block w-full bg-primary hover:bg-primary/90 text-white py-2 px-4 rounded text-center">
                View Guidelines
              </a>
            </div>
          </div>

          <div className="bg-dark/50 border border-secondary/30 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">Resources for Students</h2>
            <div className="space-y-4">
              <a href="#" className="block w-full bg-dark-blue hover:bg-dark-blue/80 text-white py-2 px-4 rounded text-left">
                Preparation Checklist
              </a>
              <a href="#" className="block w-full bg-dark-blue hover:bg-dark-blue/80 text-white py-2 px-4 rounded text-left">
                FAQs for Participants
              </a>
              <a href="#" className="block w-full bg-dark-blue hover:bg-dark-blue/80 text-white py-2 px-4 rounded text-left">
                Connect with Mentors
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Students; 